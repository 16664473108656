import React from 'react';
import styled, { css } from 'styled-components/macro';
import { sidebarWidth, smallSideBarWidth, headerHeight } from '../../../utils/css/sizes';
import { colorGrey, colorWhite, colorYellow, buttonShadow, colorBlack } from '../../../utils/css/colors';
import { Link } from 'react-router-dom';
import { body16 } from '../../../utils/css/typography';
import { sideBarStyleProps } from '../../../interfaces/admin';
import { mobile } from '../../../utils/css/media';
import { ScrollBarStyles } from '../../../utils/css/scrollBar';

export const SidebarContainer = styled.div<sideBarStyleProps>`
	width: ${props => (props.isOpen ? `${sidebarWidth}px` : `${smallSideBarWidth}px`)};
	transition: all 0.4s ease-in;
	display: flex;
	background-color: ${colorGrey};
	position: relative;
	will-change: auto;
	flex-shrink: 0;
	${mobile(css<sideBarStyleProps>`
		width: 19.2rem;
		opacity: ${({ isOpen }) => (!isOpen ? 0 : 1)};
		position: absolute;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
		z-index: ${({ isOpen }) => (!isOpen ? -1 : 999)};
		top: 38px;
		right: ${({ pageDirection }) => pageDirection === 'rtl' && '-20px'};
		left: ${({ pageDirection }) => pageDirection === 'ltr' && '-20px'};
	`)};
`;

export const SideBarList = styled.div<{ isOpen: boolean }>`
	display: flex;
	flex-direction: column;
	height: calc(100vh - (${headerHeight}px));
	color: ${colorWhite};
	padding: 3rem 0;
	z-index: 91;
	width: 100%;
	align-items: ${props => (props.isOpen ? '' : 'center')};
	overflow: auto;
	${ScrollBarStyles}
	::-webkit-scrollbar {
		display: none;
	}
	${mobile(css`
		align-items: unset;
		height: 60vh;
	`)};
`;

export const StyledLink = styled(({ active, ...rest }) => <Link {...rest} />)<{ active: boolean }>`
	${body16}
	cursor: pointer;
	padding-right: 2rem;
	padding-left: 2rem;
	text-decoration: none;
	color: ${props => (props.active ? colorYellow : colorWhite)};
	display: flex;
	height: 43px;
	align-items: center;
	&:hover {
		background-color: #444;
	}
`;

// @ts-ignore
export const SideBarIconFlex = styled(StyledLink)`
	margin-bottom: 9px;
	position: relative;
	justify-content: ${props => (props.isOpen ? '' : 'center')};
`;

export const SideBarIcon = styled.img``;

export const SideBarToggle = styled.div<{ pageDirection: 'rtl' | 'ltr' }>`
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 28px;
	left: ${props => (props.pageDirection === 'rtl' ? '-20px' : 'unset')};
	right: ${props => (props.pageDirection === 'rtl' ? 'unset' : '-20px')};
	z-index: 3;
	cursor: pointer;
	background-color: ${colorGrey};
	height: 66px;
	width: 80px;
	border-radius: 50%;
	z-index: 90;
`;

export const SideBarToggleIcon = styled.img<{ pageDirection: 'rtl' | 'ltr' }>`
	position: relative;
	top: 23px;
	right: ${props => (props.pageDirection === 'rtl' ? '60px' : 'unset')};
	left: ${props => (props.pageDirection === 'rtl' ? 'unset' : '60px')};
`;

export const SideBarToolTip = styled.div<{ pageDirection: 'rtl' | 'ltr' }>`
	position: fixed;
	background: ${colorWhite};
	border-radius: 0.4em;
	width: 125px;
	height: 20px;
	right: ${props => (props.pageDirection === 'rtl' ? '62px' : '-122px')};
	visibility: hidden;
	box-shadow: ${buttonShadow};
	color: ${colorBlack};
	padding: 2px;
	text-align: center;

	&::after {
		content: '';
		position: absolute;
		right: ${props => (props.pageDirection === 'rtl' ? '11px' : '137px')};
		top: 7%;
		width: 0;
		height: 0;
		transform: ${props => (props.pageDirection === 'rtl' ? '' : 'rotate(85deg)')};
		border: 16px solid transparent;
		border-left-color: ${colorWhite};
		border-right: 0;
		border-top: 0;
		margin-top: -1px;
		margin-right: -20px;
	}

	${SideBarIconFlex}:hover & {
		visibility: visible;
	}
`;
