import { witnessActionTypes } from '../actions/admin/witnesses.types';
import { witnessesActionTypes } from '../actions/witesses/witnesses.types';
import { IWitnessState, IWitnessProfileProps } from '../../interfaces/witnesses';

const initialState: IWitnessState = {
	witnessesList: [],
	witnessFullList: [],
	witnessCountAllPages: 0,
	totalWitnessAmountNoFilters: 0,
	witnessProfile: {} as IWitnessProfileProps,
	witnessSalons: [],
	witnessFutureAssignedSalon: [],
	witnessYearComment: [],
	witnessComments: [], 
	witnessPastSalons: {},
	witnessFutureSalons: [],
	witnessAvailabilitySalons: [],
	nextFilters: null,
	previousFilters: null,
	error: null,
	getWitnessesListIsLoading: false,
	registrationDetails: null,
};
 
const witnessesReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case witnessActionTypes.GET_WITNESSES_LIST_LOADING: {
			return { ...state, getWitnessesListIsLoading: true };
		}
		case witnessActionTypes.DELETE_WITNESS_PROFILE: {
			return { ...state, 
				witnessesList: state.witnessesList.filter(witness => witness.id !== action.payload), 
				witnessCountAllPages: state.witnessCountAllPages - state.witnessesList.filter(witness => witness.id === action.payload).length,
				totalWitnessAmountNoFilters: state.totalWitnessAmountNoFilters - state.witnessesList.filter(witness => witness.id === action.payload).length
			}
		}
		case witnessActionTypes.GET_WITNESSES_LIST_SUCCESS: {
			const { witnessesList, witnessCountAllPages, totalWitnessAmountNoFilters, nextFilters, previousFilters } = action.payload;
			return {
				...state,
				witnessesList,
				witnessCountAllPages,
				totalWitnessAmountNoFilters: totalWitnessAmountNoFilters,
				nextFilters,
				previousFilters,
				getWitnessesListIsLoading: false,
			};
		}
		case witnessActionTypes.GET_WITNESS_PROFILE_SUCCESS: {
			return { ...state, witnessProfile: action.payload };
		}
		case witnessActionTypes.GET_WITNESS_PROFILE_FAILURE: {
			return state;
		}
		case witnessActionTypes.CLEAR_WITNESS_PROFILE: {
			return { ...state, witnessProfile: {} };
		}
		case witnessActionTypes.UPDATE_WITNESS_PROFILE_SUCCESS: {
			return { ...state, witnessProfile: action.payload };
		}
		case witnessActionTypes.UPDATE_WITNESS_PROFILE_FAILURE: {
			return { ...state, error: action.payload };
		}
		case witnessActionTypes.GET_WITNESS_SALONS_SUCCESS: {
			return { ...state, witnessSalons: action.payload };
		}
		case witnessActionTypes.GET_ASSIGNED_FUTURE_SALONS_SUCCESS: {
			return { ...state, witnessFutureAssignedSalon: action.payload };
		}

		case witnessActionTypes.ADD_WITNESS_YEAR_COMMENT_SUCCESS: {
			const witnessYearComment = [...state.witnessYearComment, action.payload];
			return { ...state, witnessYearComment };
		}
		case witnessActionTypes.GET_WITNESS_YEAR_COMMENT_SUCCESS: {
			return { ...state, witnessYearComment: action.payload };
		}
		case witnessActionTypes.GET_WITNESS_YEAR_COMMENT_FAILURE: {
			return state;
		}
		case witnessActionTypes.UPDATE_WITNESS_YEAR_COMMENT_SUCCESS: {
			const updatedCommentId = action.payload.id;
			const updateList = state.witnessYearComment.findIndex(comment => comment.id === updatedCommentId);
			const updatedList = [];
			if (updateList !== -1) {
				state.witnessYearComment.splice(updateList, 1, action.payload);
			} else {
				updatedList.push(action.payload);
			}
			return { ...state, witnessYearComment: [...updatedList, ...state.witnessYearComment] };
		}
		case witnessActionTypes.DELETE_WITNESS_TEAM_COMMENT_SUCCESS: {
			const commentId = action.payload;
			const updateList = state.witnessComments.findIndex(comment => comment.id === commentId);
			if (updateList !== -1) {
				state.witnessComments.splice(updateList, 1);
			}
			return { ...state, witnessComments: [...state.witnessComments] };
		}
		case witnessActionTypes.UPDATE_WITNESS_YEAR_COMMENT_FAILURE: {
			return state;
		}
		case witnessActionTypes.ADD_WITNESS_COMMENT_SUCCESS: {
			const witnessComments = [...state.witnessComments];
			witnessComments.unshift(action.payload);
			return { ...state, witnessComments };
		}
		case witnessActionTypes.GET_WITNESS_COMMENT_SUCCESS:
			return { ...state, witnessComments: action.payload };
		case witnessActionTypes.GET_WITNESS_FUTURES_SALONS_SUCCESS: {
			const { futuresSalons } = action.payload;
			return { ...state, witnessFutureSalons: futuresSalons };
		}
		case witnessActionTypes.GET_PAST_SALONS_SUCCESS: {
			return { ...state, witnessPastSalons: action.payload };
		}
		case witnessActionTypes.GET_WITNESS_AVAILBALITY_SALONS_SUCCESS: {
			return { ...state, witnessAvailabilitySalons: action.payload };
		}
		case witnessActionTypes.UPDATE_WITNESS_AVAILBALITY_SALONS_SUCCESS: {
			return { ...state, witnessAvailabilitySalons: action.payload };
		}
		case witnessActionTypes.CLEAR_WITNESS_AVAILBALITY_SALONS: {
			return { ...state, witnessAvailabilitySalons: [] };
		}
		case witnessActionTypes.DELETE_WITNESS_ASSIGEND_SALON: {
			return {
				...state,
			};
		}
		case witnessActionTypes.GET_ALL_WITNESS_LIST_SUCCESS: {
			return {
				...state,
				witnessFullList: action.payload,
			};
		}
		case witnessesActionTypes.REGISTER_WITNESS_BIRTH_STEP_SUCCESS: {
			return {
				...state,
				registrationDetails: action.payload,
			};
		}
		case witnessesActionTypes.REGISTER_WITNESS_BIRTH_STEP_FAILURE: {
			return {
				...state,
				error: action.payload,
			};
		}
		case witnessesActionTypes.ADD_SALON_AVAILABILITY_SUCCESS: {
			return {
				...state,
				registrationDetails: {
					...state.registrationDetails,
					availabilities: [...(state.registrationDetails?.availabilities ?? []), action.payload],
				},
			};
		}
		case witnessesActionTypes.ADD_SALON_AVAILABILITY_FAILURE: {
			return {
				...state,
				error: action.payload,
			};
		}
		case witnessesActionTypes.REMOVE_SALON_AVAILABILITY_SUCCESS: {
			return {
				...state,
				registrationDetails: {
					...state.registrationDetails,
					availabilities: state.registrationDetails.availabilities.filter((availability, idx) => {
						return idx !== action.payload.idx;
					}),
				},
			};
		}
		case witnessesActionTypes.REMOVE_SALON_AVAILABILITY_FAILURE: {
			return {
				...state,
				error: action.payload,
			};
		}
		default:
			return state;
	}
};

export default witnessesReducer;
