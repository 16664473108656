export enum witnessesActionTypes {
	REGISTER_WITNESS_BIRTH_STEP_SUCCESS = 'REGISTER_WITNESS_BIRTH_STEP_SUCCESS',
	REGISTER_WITNESS_BIRTH_STEP_FAILURE = 'REGISTER_WITNESS_BIRTH_STEP_FAILURE',

	ADD_SALON_AVAILABILITY_SUCCESS = 'ADD_SALON_AVAILABILITY_SUCCESS',
	ADD_SALON_AVAILABILITY_FAILURE = 'ADD_SALON_AVAILABILITY_FAILURE',

	REMOVE_SALON_AVAILABILITY_SUCCESS = 'REMOVE_SALON_AVAILABILITY_SUCCESS',
	REMOVE_SALON_AVAILABILITY_FAILURE = 'REMOVE_SALON_AVAILABILITY_FAILURE',
}
