import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function useLanguage() {
	const { i18n } = useTranslation();

	const selectedLanguage = useMemo(() => {
		return i18n.language ? i18n.language : i18n.options.fallbackLng[0];
	}, [i18n.language, i18n.options.fallbackLng]);

	const onLangugageChagne = (lng: string) => {
		i18n.changeLanguage(lng);
	};

	useEffect(() => {
		i18n.changeLanguage(selectedLanguage);
	}, [selectedLanguage, i18n]);

	return [selectedLanguage, onLangugageChagne];
}

export default useLanguage;
