import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components/macro';

import useDetectClickOutside from '../../../common/useDetectClickOutside';
import useLanguage from '../../../common/useLanguage';
import useLogout from '../../../common/useLogout';
// @ts-ignore
import user from '../../../../assets/icons/user.svg';
import { colorWhite } from '../../../../utils/css/colors';

const Container = styled.div`
	position: relative;
`;
const Arrow = styled.div`
	width: 50px;
	height: 25px;
	position: absolute;
	top: -26%;
	transform: rotate(180deg);
	overflow: hidden;

	&::after {
		content: '';
		position: absolute;
		width: 15px;
		height: 15px;
		background: ${colorWhite};
		transform: translateX(-50%) translateY(-50%) rotate(225deg);
		top: 0;
		left: 58%;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
	}
`;
const Tooltip = styled.div<{ lng: string; isVisible: boolean }>`
	width: 115px;
	position: absolute;
	top: 40px;
	left: ${({ lng }) => (lng === 'he' ? '-35%' : 'unset')};
	right: ${({ lng }) => (lng !== 'he' ? '-35%' : 'unset')};
	background: ${colorWhite};
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
	border-radius: 6px;
	transition: opacity 0.4s ease-in;
	opacity: ${({ isVisible }) => (!isVisible ? 0 : 1)};
	z-index: ${({ isVisible }) => (!isVisible ? -1 : 999)};

	${({ lng }) =>
		lng &&
		css`
			${Arrow} {
				left: ${lng === 'he' ? '3%' : 'unset'};
				right: ${lng !== 'he' ? '-5%' : 'unset'};
			}
		`}
`;
const List = styled.ul`
	list-style-type: none;
	padding: 1.5rem 1rem;
`;
const ListItem = styled.li`
	&:not(:last-child) {
		margin-bottom: 2rem;
	}
`;

const UserMenu = ({ handleToggleLanguage }) => {
	const { t } = useTranslation();
	const { isVisible, toggleIsVisible, wrapperRef } = useDetectClickOutside();
	const [selectedLanguage] = useLanguage();
	const logout = useLogout();
	return (
		<Container onClick={toggleIsVisible} ref={wrapperRef}>
			<img src={user} alt="user logged in options" />
			<Tooltip lng={selectedLanguage} isVisible={isVisible}>
				<Arrow />
				<List>
					<ListItem onClick={logout}>{t('admin.header.userItems.logout')}</ListItem>
					<ListItem onClick={handleToggleLanguage}>{selectedLanguage === 'he' ? 'English' : 'עברית'}</ListItem>
				</List>
			</Tooltip>
		</Container>
	);
};

export default UserMenu;
