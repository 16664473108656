import React from 'react';
import { useSelector } from 'react-redux';
import * as authSelector from '../../../store/selectors/authSelectors';
import { useTranslation } from 'react-i18next';
import history from '../../../history';
// components
import useLanguage from '../../common/useLanguage';
import useLogout from '../../common/useLogout';
// utils
import { FlexRow, Logo as MenuLogo, TextLink, Row, PinkSofa, BlueSofa } from '../CommonStyles';
import { Logo } from '../Login/loginStyle';
import { ActionItem, TextItem, StyledAnchor } from '../../Admin/Header/style';
import smallRightBlackArrow from '../../../assets/icons/smallRightBlackArrow.svg';
import blueSofa from '../../../assets/icons/blueSofa.svg';
// @ts-ignore
import pinkSofa from '../../../assets/icons/pinkSofa.svg';
import envelope from '../../../assets/icons/whiteEnvelope.svg';

interface IProps {
	showHomePageSection?: boolean;
	showBackToHomePage?: boolean;
}

const Desktop: React.FC<IProps> = ({ showHomePageSection, showBackToHomePage }) => {
	const { t } = useTranslation();
	const logout = useLogout();

	const first_name = useSelector(authSelector.firstNameSelector);
	const last_name = useSelector(authSelector.lastNameSelector);
	const token = useSelector(authSelector.tokenSelector);

	const [selectedLanguage, onLanguageChange] = useLanguage();
	const navigateToHomePage = () => history.push('/');

	return (
		<>
			<PinkSofa src={pinkSofa} />
			<BlueSofa src={blueSofa} />
			<FlexRow showHomePageSection={showHomePageSection}>
				<div>
					{token !== null && showHomePageSection ? (
						<Row>
							<MenuLogo onClick={navigateToHomePage}>{t('admin.header.logo')}</MenuLogo>
							<ActionItem>{`${t('admin.header.userItems.greetings')} ${first_name} ${last_name}`}</ActionItem>
							<ActionItem clickable onClick={logout}>
								{t('admin.header.userItems.logout')}
							</ActionItem>
						</Row>
					) : showHomePageSection ? (
						<div>
							<MenuLogo>{t('admin.header.logo')}</MenuLogo>
							{showBackToHomePage && (
								<TextLink to="/">
									<Row lang={selectedLanguage}>
										<img src={smallRightBlackArrow} alt="Back to home page" />
										<span>{t('formButtons.backToHomePage')}</span>
									</Row>
								</TextLink>
							)}
						</div>
					) : null}
				</div>
				<div style={{ display: 'flex' }}>
					<ActionItem>
						<Row>
							<img src={envelope} alt="contact-us" />
							<StyledAnchor href="https://www.zikaronbasalon.com/#comp-kiybrvku3" rel="noopener noreferrer" target="_blank">
								{t('admin.header.makeContact')}
							</StyledAnchor>
						</Row>
					</ActionItem>
					<ActionItem marginLeft={1.1}>
						<TextItem onClick={() => onLanguageChange('he')} isSelected={selectedLanguage === 'he'}>
							עב
						</TextItem>
						|
						<TextItem onClick={() => onLanguageChange('en')} isSelected={selectedLanguage === 'en'}>
							ENG
						</TextItem>
					</ActionItem>
				</div>
			</FlexRow>
			{!showHomePageSection && <Logo>{t('admin.header.logo')}</Logo>}
		</>
	);
};

export default Desktop;
