import React from 'react';
import styled from 'styled-components/macro';

import useDetectClickOutside from '../../../common/useDetectClickOutside';
import Hamburger from '../../../common/Hamburger';
import Sidebar from '../../../Admin/Sidebar/Mobile';

const Container = styled.div`
	position: relative;
`;

const HamburgerMenu = () => {
	const { isVisible, toggleIsVisible, wrapperRef } = useDetectClickOutside();
	return (
		<Container ref={wrapperRef}>
			<Hamburger isHamburgerClicked={isVisible} toggleHamburgerClicked={toggleIsVisible} />
			<Sidebar isSidebarOpen={isVisible} toggleIsVisible={toggleIsVisible} />
		</Container>
	);
};

export default HamburgerMenu;
