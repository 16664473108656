import React from 'react';
import { InputContainer, InputIcon, StyledInputAdornment, StyledInput } from './inputStyle';
import { Register } from '../../Admin/Witnesses/AddWitness/utils';
import { useTranslation } from 'react-i18next';

interface IProps {
	placeholder?: string;
	type?: string;
	icon?: string;
	endIcon?: string;
	name?: string;
	register?: Register;
	errors?: any;
	required?: boolean;
	select?: boolean;
	value?: any;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	children?: any;
	flex?: number;
	defaultValue?: any;
	tabIndex?: number;
	display?: string;
	ref?: any;
	SelectProps?: any;
	shrinkPlaceholder?: boolean;
	isLabel?: boolean;
	disabled?: boolean;
	className?: string;
}

const LoginInput: React.FC<IProps> = React.forwardRef(
	({ icon, endIcon, register, errors, placeholder, display, shrinkPlaceholder, isLabel, className, ...props }) => {
		const { t } = useTranslation();
		return (
			<InputContainer display={display}>
				{/* Below the 2 native inputs are dummy inputs to disable any browser's autofill feature */}
				<input style={{ display: 'none' }} type="text" name="somefakename" />
				<input style={{ display: 'none' }} type="password" name="anotherfakename" />
				<StyledInput
					{...props}
					className={className}
					label={isLabel ? '' : placeholder}
					placeholder={isLabel ? placeholder : ''}
					error={Boolean(errors && errors[props.name])}
					helperText={errors && errors[props.name] && errors[props.name].message && String(t(errors[props.name].message))}
					inputRef={register}
					InputProps={{
						startAdornment: icon && (
							<StyledInputAdornment position="end">
								<InputIcon src={icon} alt="" />
							</StyledInputAdornment>
						),
						endAdornment: endIcon && (
							<StyledInputAdornment position="end">
								<InputIcon src={endIcon} alt="" />
							</StyledInputAdornment>
						),
					}}
					InputLabelProps={{
						shrink: shrinkPlaceholder,
					}}
					fullWidth
					autoComplete="off" // Used to disable the chrome autofill feature
				/>
			</InputContainer>
		);
	}
);

export default LoginInput;
