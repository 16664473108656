const setErrorString = (errorMessage: string, t) => {
	const registrationTranslationPath = 'errorHandler.registration.';
	const witnessTranslationPath = 'errorHandler.witness.';
	const hostTranslationPath = 'errorHandler.host.';
	const guestTranslationPath = 'errorHandler.guest.';
	const generalTranslationPath = 'errorHandler.general.';

	switch (errorMessage) {
		// Registration Errors
		case 'A user is already registered with this e-mail address.':
			return t(registrationTranslationPath + 'emailAlreadyExists');

		case 'This password is too common.':
			return t(registrationTranslationPath + 'passWordTooCommon');

		case 'The phone number entered is not valid.':
			return t(registrationTranslationPath + 'wrongPhone');

		// Witness Errors
		case 'This witness already teamed to more salons than this value.':
			return t(witnessTranslationPath + 'alreadyTeamed');

		case 'This date already exists in the wish or teaming list of this witness.':
			return t(witnessTranslationPath + 'dateAlreadyExists');

		// Host Errors
		case 'There are already more confirmed/ pending guests than max_guests':
			return t(hostTranslationPath + 'alreadyMoreConfirmed');

		case 'This salon already has teamed witness or pending/ approved guests, You can not change the event time.':
			return t(hostTranslationPath + 'alreadyTeamedWithWitness');

		case 'This host already has a salon with a witness.  please contact to admin in order to change it.':
			return t(hostTranslationPath + 'alreadyTeamedWithWitness');

		case 'Private host already has host_salon':
			return t(hostTranslationPath + 'canOnlyOpenOneSalon');

		// Guest Errors
		case 'There are not enough room to this amount of guests in this salon.':
			return t(guestTranslationPath + 'notEnoughRoom');

		case 'Guest can be registered to only one salon each year.':
			return t(guestTranslationPath + 'onlyOnceInYear');

		// General Errors
		case 'n':
			return t(generalTranslationPath + 'newPasswordFail');

		case 'Enter a valid phone number.':
			return t(generalTranslationPath + 'invalidPhone');

		case t("login.userNotAdmin"):
			return t("login.userNotAdmin");
		// Default error message
		default:
			return t(generalTranslationPath + 'somethingWentWrong');
	}
};

export default setErrorString;
