import { guestActionTypes } from './guest.types';
import { IGuestProfile } from '../../../interfaces/guests';

//Get Guest profile By id
export const getGuestProfileById = (guestId: number) => {
	return {
		type: guestActionTypes.GET_GUEST_BY_ID,
		payload: guestId
	};
};

export const getGuestProfileSuccess = data => {
	return {
		type: guestActionTypes.GET_GUEST_BY_ID_SUCCESS,
		payload: data
	};
};

//Get salon of guest by id
export const getGuestSalonById = (guestId: number) => {
	return {
		type: guestActionTypes.GET_GUEST_SALON,
		payload: guestId
	};
};

export const getGuestSalonByIdSuccess = data => {
	return {
		type: guestActionTypes.GET_GUEST_SALON_SUCCESS,
		payload: data
	};
};

export const clearGuestProfile = () => ({
	type: guestActionTypes.CLEAR_GUEST_PROFILE
});

//Update Guest Profile
export const updateGuestProfile = (guestId: number, data: any, changeFromHost: boolean) => ({
	type: guestActionTypes.UPDATE_GUEST_PROFILE,
	payload: { guestId, guestUpdateData: data, changeFromHost }
});

export const updateGuestProfileSuccess = data => ({
	type: guestActionTypes.UPDATE_GUEST_PROFILE_SUCCESS,
	payload: data
});

//Delete Guest Salon
export const deleteGuestSalon = (salonId: number) => ({
	type: guestActionTypes.DELETE_GUEST_SALON,
	payload: salonId
});

//Update Guest Salon
export const updateGuestSalon = (salonId: number, guestId: number, data: any, cb: any) => ({
	type: guestActionTypes.UPDATE_GUEST_SALON,
	payload: { salonId, guestId, guestSalonUpdated: data, cb }
});

export const updateGuestSalonSuccess = data => ({
	type: guestActionTypes.UPDATE_GUEST_SALON_SUCCESS,
	payload: data
});

// register a guest to a salon
export const createGuestSalon = (data, cb) => {
	return {
		type: guestActionTypes.CREATE_GUEST_SALON,
		payload: { data, cb }
	};
};
export const createGuestSalonSucess = data => ({
	type: guestActionTypes.CREATE_GUEST_SALON_SUCCESS,
	payload: data
});
export const createGuestSalonFailure = error => ({
	type: guestActionTypes.CREATE_GUEST_SALON_FAILURE,
	payload: error
});

export const clearGuestId = () => ({
	type: guestActionTypes.CLEAR_GUEST_ID
});

export const saveGuestPersonalDetailsEdit = (data: Partial<IGuestProfile>) => ({
	type: guestActionTypes.SAVE_GUEST_PERSONAL_DETAILS_EDIT,
	payload: data
});

export const clearGuestPersonalDetailsEdit = () => ({
	type: guestActionTypes.CLEAR_GUEST_PERSONAL_DETAILS_EDIT
});
