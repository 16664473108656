import { API_URL } from '../../utils/constants/api';
import axios from 'axios';
import Qs from 'qs';
import { localStorageAuthKey, unauthorized_status, forbidden_status } from '../../utils/constants/auth';
import history from '../../history';

export const addRequestObject = store => next => action => {
	const authReducer = JSON.parse(localStorage.getItem(localStorageAuthKey));
	if (authReducer === null) next(action);
	if (!action.request || !action.request.url) {
		return next(action);
	}
	action.request.url = API_URL + action.request.url;
	if (!action.request.hasOwnProperty('headers')) {
		action.request.headers = {};
	}
	if (JSON.parse(authReducer.token)) action.request.headers.Authorization = `Bearer ${authReducer.token.replace(/['"]+/g, '')}`;
	next(action);
};

export const instance = axios.create({
	paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'repeat' }),
	baseURL: API_URL
});

// intercept unauthorized errors and logout user
instance.interceptors.response.use(
	response => response,
	error => {
		const {
			response: { status }
		} = error;
		if (status === unauthorized_status || status === forbidden_status) {
			// log user out
			localStorage.clear();
			history.push('/');
		}
		return Promise.reject(error);
	}
);

export const setAxiosHeader = token => {
	if (token) {
		instance.defaults.headers.common.Authorization = `Bearer ${token}`;
	} else {
		instance.defaults.headers.common.Authorization = null;
	}
};
