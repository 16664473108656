import { hostsActionTypes } from '../actions/hosts/hosts.types';
import { IHostState } from '../../interfaces/hosts';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { authActionTypes } from '../actions/auth/auth.types';

const initialState: IHostState = {
	hostProfile: null,
	hostSalonsList: null,
	hostSalons: null,
	hostId: null,
	hostsList: [],
	hostsFullList: [],
	hostsCountAllPages: 0,
	totalHostsAmountNoFilters: 0,
	nextFilters: null,
	previousFilters: null,
	isGetHostsListLoading: false,
	hostRegistrationDetails: null
};

const hostsReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case hostsActionTypes.GET_HOST_PROFILE_SUCCESS:
		case authActionTypes.CHANGE_GUEST_TO_HOST_SUCCESS: {
			const hostProfile = action.payload;
			return { ...state, hostProfile, hostId: action.payload.id };
		}
		case hostsActionTypes.GET_HOST_LIST_LOADING: {
			return { ...state, isGetHostsListLoading: true };
		}
		case hostsActionTypes.DELETE_HOST_PROFILE: {
			return {
				...state,
				// @ts-ignore
				hostsList: state.hostsList.filter(host => host.id !== action.payload),
				// @ts-ignore
				hostsCountAllPages: state.hostsCountAllPages - state.hostsList.filter(host => host.id === action.payload).length,
				totalHostsAmountNoFilters:
					// @ts-ignore
					state.totalHostsAmountNoFilters - state.hostsList.filter(host => host.id === action.payload).length
			};
		}
		case hostsActionTypes.GET_ALL_HOSTS_SUCCESS: {
			const { hostsList, hostsCountAllPages, totalHostsAmountNoFilters, nextFilters, previousFilters } = action.payload;
			return {
				...state,
				hostsList,
				hostsCountAllPages,
				totalHostsAmountNoFilters,
				nextFilters,
				previousFilters,
				isGetHostsListLoading: false
			};
		}
		case hostsActionTypes.GET_ALL_HOSTS_EXPORT_SUCCSSES: {
			return {
				...state,
				hostsFullList: action.payload
			};
		}
		case hostsActionTypes.UPDATE_HOST_PROFILE_SUCCESS: {
			const hostProfile = action.payload;
			return { ...state, hostProfile };
		}
		case hostsActionTypes.GET_HOST_SALONS_LIST_SUCCESS: {
			const hostSalonsList = action.payload;
			return { ...state, hostSalonsList };
		}
		case hostsActionTypes.CREATE_HOST_SUCCESS:
			return {
				...state,
				hostId: action.payload
			};
		case hostsActionTypes.GET_HOST_SALONS_SUCCESS:
			return {
				...state,
				hostSalons: action.payload
			};
		case hostsActionTypes.RESET_HOST_ID:
			return {
				...state,
				hostId: null
			};
		case hostsActionTypes.CLEAR_HOST_SALON_LIST:
			return {
				...state,
				hostProfile: {},
				hostSalonsList: {},
				hostSalons: {}
			};
		case hostsActionTypes.DELETE_HOST_SALON_BY_ID: {
			const { hostSalonId, salonYear } = action.payload;

			const updateList = state.hostSalonsList[salonYear].findIndex(salon => salon.id === hostSalonId);

			if (updateList !== -1) state.hostSalonsList[salonYear].splice(updateList, 1);
			if (state.hostSalonsList[salonYear].length === 0) delete state.hostSalonsList[salonYear];

			return { ...state, hostSalonsList: { ...state.hostSalonsList } };
		}
		case authActionTypes.CHANGE_GUEST_TO_HOST_FAILURE: {
			return {
				...state,
				error: action.payload
			};
		}
		case authActionTypes.SAVE_HOST_REGISTRATION_DETAILS: {
			return {
				...state,
				hostRegistrationDetails: {
					...state.hostRegistrationDetails,
					...action.payload
				}
			};
		}
		case authActionTypes.CLEAR_HOST_REGISTRATION_DETAILS: {
			return {
				...state,
				hostRegistrationDetails: []
			};
		}
		default:
			return state;
	}
};

const persistConfig = {
	key: 'hosts',
	storage: storage,
	whitelist: ['hostId', 'hostRegistrationDetails']
};

export default persistReducer(persistConfig, hostsReducer);
