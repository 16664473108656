import { instance as axios } from '../middleware/auth.middleware';

const guestsUrl = '/api/guests';
const guestsSalonsUrl = '/api/salonguests';
const availableSalonsUrl = 'api/host_salons_map';

export const getLanguagesList = () => {
	return axios.get(`/api/languages/`);
};

export const getAvailableSalonsList = params => {
	return axios.get(`${availableSalonsUrl}/`, { params });
};

export const createGuestcall = (guestId: number, data: any) => {
	return axios.patch(`${guestsUrl}/${guestId}/`, data);
};

export const getGuestProfileCall = (guestId: string) => {
	return axios.get(`${guestsUrl}/${guestId}/`);
};

export const getGuestSalonCall = (guestId: number) => {
	return axios.get(`${guestsSalonsUrl}/`, { params: { guest: guestId, denied: false } });
};

export const updateGuestProfileCall = (guestId: string, guestData: any) => {
	return axios.patch(`${guestsUrl}/${guestId}/`, guestData);
};

export const deleteGuestSalonCall = (salonId: string) => {
	return axios.delete(`${guestsSalonsUrl}/${salonId}/`);
};

export const updateGuestRequestCall = (requestId: number, data) => {
	return axios.patch(`${guestsSalonsUrl}/${requestId}/`, data);
};
export const updateGuestSalonCall = (salonId: string, salonData: any) => {
	return axios.patch(`${guestsSalonsUrl}/${salonId}/`, salonData);
};
export const createGuestSalonCall = data => {
	return axios.post(`${guestsSalonsUrl}/`, data);
};

export const getAllGuestsCall = params => {
	return axios.get(`${guestsUrl}/`, { params });
};

//Get all salons list For export

export const getAllGuestListCall = params => {
	if (params) return axios.get(`${guestsUrl}/`, { params });
	else return axios.get(`${guestsUrl}/?pagination=false`);
};
