import { errorActionTypes } from '../actions/errors/error.types';

export interface IErrorState {
	errorKeys: {
		parameter: string;
		message: string;
	}[];
}

const initialState: IErrorState = {
	errorKeys: []
};

const errorReducer = (state = initialState, action: any): IErrorState => {
	switch (action.type) {
		case errorActionTypes.HANDLE_ERROR_FROM_SERVER: {
			return { ...state, errorKeys: action.payload };
		}

		default:
			return state;
	}
};

export default errorReducer;
