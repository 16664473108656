import { instance as axios } from '../middleware/auth.middleware';

const hostsUrl = '/api/hosts';
const hostSalonUrl = '/api/host_salons';
const hostSalonListUrl = '/api/host_salons_list';

// Host

export const getHostProfileCall = (hostId: string) => {
	return axios.get(`${hostsUrl}/${hostId}/`);
};

//Get host list
export const getHostsListCall = params => {
	return axios.get(`${hostsUrl}/`, { params });
};

export const updateHostProfileCall = (hostId: string, data: any) => {
	return axios.patch(`${hostsUrl}/${hostId}/`, data);
};

export const createHostCall = (data: any) => {
	return axios.post(`${hostsUrl}/`, data);
};

//Get all salons list

export const getAllSalonsListCall = params => {
	if (params) return axios.get(`${hostSalonUrl}_list/`, { params });
	else return axios.get(`${hostSalonListUrl}/?pagination=false`);
};

// Host salons

export const getHostSalonsSummaryCall = (hostId: string) => {
	return axios.get(`${hostsUrl}/${hostId}/summaries/`);
};

export const getHostSalonByIdCall = (salonId: number) => {
	return axios.get(`${hostSalonUrl}/${salonId}/`);
};

export const getHostSalonByHostIdCall = (hostId: number) => {
	return axios.get(`${hostSalonUrl}/`, { params: { host: hostId } });
};

export const getHostSalonCommentsCall = (salonId: number) => {
	return axios.get(`${hostSalonUrl}/${salonId}/comments/`);
};

export const addNewHostSalonCommentCall = (salonId: number, data: any) => {
	return axios.post(`${hostSalonUrl}/${salonId}/comments/`, data);
};

export const deleteWitnessTeamCommentCall = (salonId: string | number, commentId: number) => {
	return axios.delete(`${hostSalonUrl}/${salonId}/comments/${commentId}/`);
};

export const updateHostSalonCall = (salonId: number, data: any) => {
	return axios.patch(`${hostSalonUrl}/${salonId}/`, data);
};

export const addnNewHostSalonCall = (data: any) => {
	return axios.post(`${hostSalonUrl}/`, data);
};

export const getSalonsCall = params => {
	return axios.get(`${hostSalonUrl}_list/`, { params });
};

export const getHostSalonsListForTeaming = params => {
	return axios.get(`${hostSalonUrl}_teaming/`, { params });
};

export const getHostSalonTeamingDetails = (id: number) => {
	return axios.get(`${hostSalonUrl}_teaming/${id}/`);
};

export const getRelevantWitnessesForTeaming = (hostSalonId: number, cityParams?: any, nextPaginationPage?: number) => {
	return axios.get(
		`${hostSalonUrl}/${hostSalonId}/relevant_witnesses/`
	);
};

export const getGuestRequestsCall = hostSalonId => {
	return axios.get(`api/salonguests/?salon=${hostSalonId}`);
};

export const deleteHostSalonByIdCall = (hostSalonId: number) => {
	return axios.delete(`${hostSalonUrl}/${hostSalonId}/`);
};

//Get all Hosts list For export

export const getAllHostsListCall = params => {
	if (params) return axios.get(`${hostsUrl}/`, { params });
	else return axios.get(`${hostsUrl}/?pagination=false`);
};

// Get host salon training

export const getHostSalonTrainingList = () => {
	return axios.get(`/api/host_salon_training/`);
};

// Delete host profile
export const deleteHostProfileCall = (hostId: any) => {
	return axios.delete(`${hostsUrl}/${hostId}/`);
};
