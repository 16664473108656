import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@material-ui/core/styles';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
// components
import Header from './Header';
// utils
import { Container, MobilePinkBg } from './CommonStyles';
import { heTheme, enTheme } from '../../utils/MuiTheme';
import { MobileContactFooter, MobileContactLink } from './Login/loginStyle';
import { mobileWidth } from '../../utils/css/sizes';
import useWindowSize from '../common/useWindowSize';
import loginBGMobile from '../../assets/icons/loginBGMobile.svg';
import getDirection from '../../utils/getLanguageDirection';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const Layout = ({ showHomePageSection = true, showBackToHomePage = true, children }) => {
	const {
		i18n: { language },
		t
	} = useTranslation();

	const langDirection = getDirection();

	const [width] = useWindowSize();
	return (
		<ThemeProvider theme={language === 'en' ? enTheme : heTheme}>
			<StylesProvider jss={jss}>
				<Container lng={language} setJustify={width <= mobileWidth ? 'space-between' : ''}>
					<Header showHomePageSection={showHomePageSection} showBackToHomePage={showBackToHomePage} />
					<MobilePinkBg src={loginBGMobile} alt="login" />
					{children}
					{width <= mobileWidth && (
						<MobileContactFooter>
							{t('login.NeedHelpText')}
							<MobileContactLink
								href="https://www.zikaronbasalon.com/contact-us"
								rel="noopener noreferrer"
								target="_blank"
								direction={langDirection}
							>
								{t('login.ContactUs')}
							</MobileContactLink>
						</MobileContactFooter>
					)}
				</Container>
			</StylesProvider>
		</ThemeProvider>
	);
};

export default Layout;
