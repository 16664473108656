import { routes } from './routes';
//SideBar Icons
import Dashboard from '../../assets/icons/Dashboard.svg';
import Salons from '../../assets/icons/Salons.svg';
import hosts from '../../assets/icons/hosts.svg';
import witness from '../../assets/icons/witness.svg';
import Teams from '../../assets/icons/Teams.svg';
import guests from '../../assets/icons/guests.svg';
import community from '../../assets/icons/Community.svg';
import volunteers from '../../assets/icons/volunteers.svg';
import envalopSidebar from '../../assets/icons/envalopSidebar.svg';
import calendarSidebar from '../../assets/icons/calendarSidebar.svg';
import Testemonials from '../../assets/icons/Testemonials.svg';
//SideBar Icons Seleceted
import DashboardSelect from '../../assets/icons/DahboardSelect.svg';
import SalonsSelected from '../../assets/icons/SalonsSelected.svg';
import hostSelected from '../../assets/icons/hostSelected.svg';
import witnessSelecetd from '../../assets/icons/witnessSelecetd.svg';
import AddwitnessSelected from '../../assets/icons/AddwitnessSelected.svg';
import TeamsSelected from '../../assets/icons/TeamsSelected.svg';
import guestsSelected from '../../assets/icons/guestsSelected.svg';
import communitySelected from '../../assets/icons/communitySelected.svg';
import volunteersSelected from '../../assets/icons/volunteersSelected.svg';
import envalopSidebarSelelcted from '../../assets/icons/envalopSidebarSelelcted.svg';
import calendarSidebarSelected from '../../assets/icons/calendarSidebarSelected.svg';

const elements = [
	{
		title: 'dashboard',
		link: '/',
		icon: Dashboard,
		selectedIcon: DashboardSelect,
		authorizedAdmins: ['super admin', 'volunteer admin', 'general admin', 'global admin', 'host admin', 'testimonials admin'],
	},
	{
		title: 'salons',
		link: routes.salons.root,
		icon: Salons,
		selectedIcon: SalonsSelected,
		authorizedAdmins: ['super admin', 'volunteer admin', 'general admin', 'global admin', 'host admin'],
	},
	{
		title: 'hosts',
		link: routes.hosts.list,
		icon: hosts,
		selectedIcon: hostSelected,
		authorizedAdmins: ['super admin', 'volunteer admin', 'general admin', 'global admin', 'host admin'],
	},
	{
		title: 'witness',
		link: routes.witness.list,
		icon: witness,
		selectedIcon: witnessSelecetd,
		authorizedAdmins: ['super admin', 'volunteer admin', 'general admin', 'global admin', 'testimonials admin'],
	},
	{
		title: 'addWitness',
		link: routes.witness.addWitness,
		icon: Testemonials,
		selectedIcon: AddwitnessSelected,
		authorizedAdmins: ['super admin', 'volunteer admin', 'general admin', 'global admin'],
	},
	{
		title: 'assigns',
		link: routes.salonTeaming.table,
		extraLink: routes.salonTeaming.map,
		icon: Teams,
		selectedIcon: TeamsSelected,
		authorizedAdmins: ['super admin', 'volunteer admin', 'general admin', 'global admin'],
	},
	{
		title: 'guests',
		link: routes.guests.list,
		icon: guests,
		selectedIcon: guestsSelected,
		authorizedAdmins: ['super admin', 'general admin', 'global admin'],
	},
	{
		title: 'communities',
		link: '/',
		icon: community,
		selectedIcon: communitySelected,
		authorizedAdmins: ['super admin', 'general admin', 'global admin', 'host admin', 'testimonials admin'],
	},
	{
		title: 'volunteers',
		link: '/',
		icon: volunteers,
		selectedIcon: volunteersSelected,
		authorizedAdmins: ['super admin', 'general admin', 'global admin', 'host admin', 'testimonials admin'],
	},
	{
		title: 'contacts',
		link: '/',
		icon: envalopSidebar,
		selectedIcon: envalopSidebarSelelcted,
		authorizedAdmins: ['super admin', 'volunteer admin', 'general admin', 'global admin', 'host admin', 'testimonials admin'],
	},
	{
		title: 'setDates',
		link: '/',
		icon: calendarSidebar,
		selectedIcon: calendarSidebarSelected,
		authorizedAdmins: ['super admin', 'general admin', 'global admin', 'host admin', 'testimonials admin'],
	},
];

export default elements;
