export enum hostsActionTypes {
	GET_HOST_PROFILE = 'GET_HOST_PROFILE',
	GET_HOST_PROFILE_SUCCESS = 'GET_HOST_PROFILE_SUCCESS',
	GET_HOST_PROFILE_FAILURE = 'GET_HOST_PROFILE_FAILURE',

	DELETE_HOST_PROFILE = 'DELETE_HOST_PROFILE',

	GET_HOST_SALONS_LIST = 'GET_HOST_SALONS_LIST',
	GET_HOST_SALONS_LIST_SUCCESS = 'GET_HOST_SALONS_LIST_SUCCESS',

	GET_HOST_SALONS = 'GET_HOST_SALONS',
	GET_HOST_SALONS_SUCCESS = 'GET_HOST_SALONS_SUCCESS',

	UPDATE_HOST_PROFILE = 'UPDATE_HOST_PROFILE',
	UPDATE_HOST_PROFILE_SUCCESS = 'UPDATE_HOST_PROFILE_SUCCESS',
	UPDATE_HOST_PROFILE_FAILURE = 'UPDATE_HOST_PROFILE_FAILURE',

	UPDATE_HOST_SALON = 'UPDATE_HOST_SALON',
	UPDATE_HOST_SALON_SUCCESS = 'UPDATE_HOST_SALON_SUCCESS',
	UPDATE_HOST_SALON_FAILURE = 'UPDATE_HOST_SALON_FAILURE',

	ADD_HOST_SALON = 'ADD_HOST_SALON',
	ADD_HOST_SALON_SUCCESS = 'ADD_HOST_SALON_SUCCESS',
	ADD_HOST_SALON_FAILURE = 'ADD_HOST_SALON_FAILURE',

	CREATE_HOST = 'CREATE_HOST',
	CREATE_HOST_SUCCESS = 'CREATE_HOST_SUCCESS',
	CREATE_HOST_FAILURE = 'CREATE_HOST_FAILURE',

	CREATE_HOST_SALON_FOR_EXISTING_USER = 'CREATE_HOST_SALON_FOR_EXISTING_USER',

	RESET_HOST_ID = 'RESET_HOST_ID',

	CLEAR_HOST_SALON_LIST = 'CLEAR_HOST_SALON_LIST',

	DELETE_HOST_SALON_BY_ID = 'DELETE_HOST_SALON_BY_ID',

	DELETE_HOST_SALON_COMMENT = 'DELETE_HOST_SALON_COMMENT',

	GET_ALL_HOSTS = 'GET_ALL_HOSTS',
	GET_ALL_HOSTS_SUCCESS = 'GET_ALL_HOSTS_SUCCESS',

	GET_ALL_HOSTS_LIST = 'GET_ALL_HOSTS_LIST',
	GET_ALL_HOSTS_LIST_SUCCSSES = 'GET_ALL_HOSTS_LIST_SUCCSSES',
	GET_HOST_LIST_LOADING = 'GET_HOST_LIST_LOADING',
	GET_ALL_HOSTS_EXPORT = 'GET_ALL_HOSTS_EXPORT',
	GET_ALL_HOSTS_EXPORT_SUCCSSES = 'GET_ALL_HOSTS_EXPORT_SUCCSSES'
}
