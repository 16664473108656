export const adminUsers: Array<string> = [
	'super admin',
	'general admin',
	'global admin',
	'volunteer admin',
	'host admin',
	'testimonials admin'
];

export const authorizedAdminForHost: Array<string> = [
	'super admin',
	'general admin',
	'global admin',
	'volunteer admin',
	'testimonials admin'
];
