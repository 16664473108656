import React, { useState, useEffect } from 'react';
import { SidebarContainer, SideBarList, StyledLink } from './SidebarStyle';
import sideBarbuttons from '../../../utils/constants/sideBar';
import { useTranslation } from 'react-i18next';

interface IProps {
	isSidebarOpen?: boolean;
	toggleIsVisible?: () => void;
}

const Mobile: React.FC<IProps> = ({ isSidebarOpen = false, toggleIsVisible } = {}) => {
	const { t, i18n } = useTranslation();
	const [pageDirection, setPageDirection] = useState<'ltr' | 'rtl'>('rtl');

	useEffect(() => {
		if (i18n.language === 'he' && pageDirection !== 'rtl') {
			setPageDirection('rtl');
		} else if (i18n.language !== 'he' && pageDirection === 'rtl') {
			setPageDirection('ltr');
		}
	}, [i18n.language, pageDirection]);

	return (
		<SidebarContainer isOpen={isSidebarOpen} pageDirection={pageDirection}>
			<SideBarList isOpen={isSidebarOpen}>
				{sideBarbuttons.map(button => (
					<div key={button.title} onClick={toggleIsVisible}>
						<StyledLink to={button.link} active={window.location.pathname === button.link}>
							{t(`admin.sideBar.${button.title}`)}
						</StyledLink>
					</div>
				))}
			</SideBarList>
		</SidebarContainer>
	);
};

export default Mobile;
