import { guestActionTypes } from './guest.types';

//Get Guests list
export const getGuestsList = params => {
	return {
		type: guestActionTypes.GET_ALL_GUESTS,
		payload: params
	};
};

export const getGuestsListSucsses = data => {
	return {
		type: guestActionTypes.GET_ALL_GUESTS_SUCCESS,
		payload: data
	};
};

export const getAllGuestsList = params => {
	return {
		type: guestActionTypes.GET_ALL_GUESTS_LIST,
		payload: params
	};
};

export const getAllGuestsListSuccess = data => {
	return {
		type: guestActionTypes.GET_ALL_GUESTS_LIST_SUCCSSES,
		payload: data
	};
};
