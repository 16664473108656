import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputAdornment } from '@material-ui/core';
import { InputContainer, InputIcon, StyledInputAdornment, StyledInput, ShowPasswordIcon } from './inputStyle';
import { Register } from '../../Admin/Witnesses/AddWitness/utils';
// @ts-ignore
import eyeShut from '../../../assets/icons/eyeShut.svg';
import eyeOpen from '../../../assets/icons/eyeOpen.svg';

interface IProps {
	placeholder: string;
	type?: string;
	icon?: string;
	name: string;
	register: Register;
	errors?: any;
	isLabel?: boolean;
}

const PasswordInput: React.FC<IProps> = ({ register, icon, errors, placeholder, isLabel, ...props }) => {
	const { t } = useTranslation();
	const [type, setType] = useState(true);
	return (
		<InputContainer>
			<StyledInput
				{...props}
				label={isLabel ? '' : placeholder}
				placeholder={isLabel ? placeholder : ''}
				type={type ? 'password' : 'text'}
				error={Boolean(errors && errors[props.name])}
				helperText={errors && errors[props.name] && errors[props.name].message && String(t(errors[props.name].message))}
				inputRef={register}
				fullWidth
				InputProps={{
					startAdornment: icon && (
						<StyledInputAdornment position="end">
							<InputIcon src={icon} alt="" />
						</StyledInputAdornment>
					),
					endAdornment: (
						<InputAdornment position="start">
							<ShowPasswordIcon src={type ? eyeShut : eyeOpen} alt="toggle password type" onClick={() => setType(!type)} />
						</InputAdornment>
					),
				}}
			/>
		</InputContainer>
	);
};

export default PasswordInput;
