import React from 'react';
import styled, { css } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as authSelector from '../../../../store/selectors/authSelectors';
// components
import useLanguage from '../../../common/useLanguage';
import UserMenu from './UserMenu';
import HamburgerMenu from './HamburgerMenu';
// utils
import { Logo } from '../../Login/loginStyle';
import { mobile } from '../../../../utils/css/media';
import { colorYellow } from '../../../../utils/css/colors';
import useIsUserAdmin from '../../../../utils/useIsUserAdmin';
import * as salonsSelector from '../../../../store/selectors/salonsSelectors';
import { isIOS } from 'react-device-detect';

const Header = styled.div<{ lng: string; isIOSModalOpen: boolean }>`
	display: flex;
	max-height: 8rem;
	width: 100%;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.24);
	align-items: center;
	padding: 1rem 2rem 0 1.8rem;
	background: ${colorYellow};
	direction: ${({ lng }) => (lng === 'he' ? 'rtl' : 'ltr')};
	${mobile(css<{ isIOSModalOpen: boolean }>`
		position: ${({ isIOSModalOpen }) => (isIOSModalOpen && isIOS ? 'fixed' : 'static')};
		top: 0;
		left: 0;
		right: 0;
	`)}
`;

const TextItem = styled.div`
	opacity: 0.54;
	cursor: pointer;
`;

const Mobile = () => {
	const { t } = useTranslation();
	const [selectedLanguage, onLangugageChagne] = useLanguage();

	const token = useSelector(authSelector.tokenSelector);
	const isIOSModalOpen = useSelector(salonsSelector.isIOSModalOpenSelector);

	const isLoggedIn = token !== null;
	const isUserAdmin = useIsUserAdmin();
	const handleToggleLanguage = () => {
		return selectedLanguage === 'he' ? onLangugageChagne('en') : selectedLanguage === 'en' ? onLangugageChagne('he') : null;
	};
	return (
		<Header lng={selectedLanguage} isIOSModalOpen={isIOSModalOpen}>
			{isLoggedIn && isUserAdmin && <HamburgerMenu />}
			<Logo style={{ margin: '0 auto' }}>{t('admin.header.logo')}</Logo>
			{isLoggedIn ? (
				<UserMenu handleToggleLanguage={handleToggleLanguage} />
			) : (
				<TextItem style={{ zIndex: 100 }} onClick={handleToggleLanguage}>
					{selectedLanguage === 'he' ? 'ENG' : 'עב'}
				</TextItem>
			)}
		</Header>
	);
};

export default Mobile;
