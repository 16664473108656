import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import loginBackground from '../../../../assets/icons/loginBackground.svg';
import { logoHeader } from '../../../../utils/css/typography';
import { colorBlack, colorWhite } from '../../../../utils/css/colors';
import { mobile } from '../../../../utils/css/media';

export const Container = styled.div<{ lng?: string }>`
	display: flex;
	position: relative;
	flex-direction: column;
	align-items: center;
	background: url(${loginBackground}) no-repeat cover center;
	height: 100vh;
	overflow-x: hidden;
	direction: ${({ lng }) => (lng ? (lng === 'en' ? 'ltr' : 'rtl') : 'rtl')};
`;

export const FlexRow = styled.div`
	display: flex;
	justify-content: space-between;
	width: 95%;
	padding-top: 3rem;
`;

export const Logo = styled.div`
	${logoHeader};
	cursor: pointer;
`;

export const Card = styled.div<{ height?: string }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 3% 0;
	background-color: ${colorWhite};
	border-radius: 6px;
	position: relative;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
	width: 692px;
	min-width: 27vw;
	padding: 1% 7.3%;
	height: ${({ height }) => (height ? height : null)};

	${mobile(css`
		min-width: 87%;
		padding: 6%;
		width: 90%;
		min-height: 50.3rem;
		max-height: 61.6rem;
	`)};
`;

export const TextLink = styled(Link)<{ underlined?: string }>`
	text-decoration: ${({ underlined }) => (underlined ? 'underline' : 'none')};
	color: ${colorBlack};
`;

export const TextModalTerms = styled.div`
	text-decoration: underline;
	display: inline;
	cursor: pointer;
`;

export const Row = styled.div`
	display: flex;
	align-items: center;
	& > span {
		margin-right: 5px;
	}
`;

export const ButtonContainer = styled.div`
	margin-top: 7vh;
	display: flex;
	width: 100%;
	justify-content: center;

	${mobile(css`
		margin-top: 4vh;

		& > button {
			width: 85%;
			padding: 1.3rem 1.4rem;
		}
	`)};
`;

export const FlexFieldRow = styled.div<{ alignItems?: string }>`
	display: flex;
	align-items: ${({ alignItems }) => alignItems};
	width: 100%;
`;

export const FlexItem = styled.div<{ flex?: number; display?: string }>`
	flex: ${({ flex }) => flex};
	display: ${({ display }) => display};
	&:not(:last-child) {
		margin-inline-end: 5rem;
	}
`;

export const RadioButtonsContainer = styled.div<{ margin?: number; customJustify?: string }>`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: ${props => (props.customJustify ? props.customJustify : 'space-between')};
	margin-inline-start: ${({ margin }) => margin}rem;
	margin: 1px 0;

	.privateOrOrgRadioBtns {
		margin-top: 7.4rem;
	}

	${mobile(css`
		flex-direction: column;
		align-items: unset;
		display: flex;
		flex-direction: row;
		align-items: center;
	`)};
`;

export const RadioContainer = styled.div<{ marginEnd?: number }>`
	margin-inline-end: ${({ marginEnd }) => marginEnd};
	${mobile(css`
		margin-inline-start: -1rem;
	`)};
`;

export const RadioLabel = styled.span<{ fontSize?: string }>`
	color: #878787;
	font-size: ${props => props.fontSize};

	${mobile(css`
		margin-left: 5px;
		display: flex;
		margin-bottom: 4px;
	`)};
`;

export const Tooltip = styled.div`
	display: none;
	position: absolute;
	height: auto;
	width: auto;
	min-width: 28rem;
	background-color: #fff;
	overflow: hidden;
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
	padding: 1rem;
	z-index: 1;

	&:hover {
		display: block;
	}

	${mobile(css`
		&.registerHost-witnessNeededTooltip {
			right: -15rem;
		}
	`)}
`;

export const IconContainer = styled.span<{ isAbsolute?: boolean; direction?: 'rtl' | 'ltr'; imgRight?; imgTop? }>`
	cursor: pointer;
	position: ${props => (props.isAbsolute ? 'absolute' : 'relative')};
	left: ${props => (props.isAbsolute && props.direction === 'rtl' ? '130px' : '')};
	right: ${props => (props.direction === 'ltr' ? '135px' : '')};
	bottom: ${props => (props.isAbsolute ? '48%' : '')};

	${mobile(css<{ isAbsolute?: boolean; direction?: 'rtl' | 'ltr' }>`
		bottom: ${props => (props.isAbsolute && props.direction === 'ltr' ? '49%' : '')};
		left: ${props => (props.isAbsolute && props.direction === 'rtl' ? '43px' : '')};
		right: ${props => (props.direction === 'ltr' ? '50px' : '')};
	`)};

	&:hover {
		& > div {
			display: block;
			position: ${props => (props.isAbsolute ? 'absolute' : '')};
			left: ${props => (props.isAbsolute && props.direction === 'rtl' ? '5px' : '')};
			right: ${props => (props.direction === 'ltr' ? '5px' : '')};
		}
	}

	& > img {
		position: absolute;
		top: ${props => (props.imgTop ? props.imgTop : '-5px')};
		right: ${props => (props.imgRight ? props.imgRight : '5px')};
		left: 5px;

		${mobile(css`
			right: -1px;
		`)};
	}
`;

export const LogoContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 2.5rem;
`;

export const LogoContainerGuest = styled.div`
	display: flex;
	margin-top: 2.5rem;
	justify-content: center;

	${mobile(css`
		margin-top: 0;
		order: -1;
	`)};
`;

export const ButtonsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 2.5rem;
	width: 100%;
`;

export const LabeldRadioButtonsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	${mobile(css`
		align-items: unset;
	`)};
`;
