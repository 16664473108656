import { salonSearchMapActionTypes } from './salonSearchMap.types';
import { Language } from '../../../interfaces/admin';

export const getLanguages = () => {
	return {
		type: salonSearchMapActionTypes.GET_LANGUAGES,
		request: {
			url: `/api/languages/`,
			method: 'GET'
		}
	};
};

export const getLanguagesSuccess = (languages: Language) => ({
	type: salonSearchMapActionTypes.GET_LANGUAGES_SUCCESS,
	payload: languages
});

export const getLanguagesFailure = (error: string) => ({
	type: salonSearchMapActionTypes.GET_LANGUAGES_FAILURE,
	error: error
});

export const getAvailableSalons = () => {
	return {
		type: salonSearchMapActionTypes.GET_AVAILABLE_SALONS
	};
};

export const getAvailableSalonsListInit = (params, digitalSalonTable?: boolean) => {
	return {
		type: salonSearchMapActionTypes.GET_AVAILABLE_SALONS_LIST_INIT,
		payload: { params, digitalSalonTable }
	};
};

export const getAvailableSalonsListSuccess = (data: any) => {
	return {
		type: salonSearchMapActionTypes.GET_AVAILABLE_SALONS_LIST_SUCCESS,
		payload: data
	};
};

export const getAvailableSalonsListFail = (error: string) => {
	return {
		type: salonSearchMapActionTypes.GET_AVAILABLE_SALONS_LIST_FAIL,
		error: error
	};
};

//Get digital salon table data

export const getDigitalSalonsSuccess = data => {
	return {
		type: salonSearchMapActionTypes.GET_AVAILABLE_DIGITAL_SALONS_TABLE_SUCCESS,
		payload: data
	};
};
