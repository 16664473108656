import { all, fork } from 'redux-saga/effects';
import authSaga from './auth.saga';
import adminSaga from './admin.saga';
import witnessesSaga from './witnesses.saga';
import hostsSaga from './hosts.saga';
import salonsSaga from './salons.saga';
import guestSaga from './guest.saga';

export default function*() {
	yield all([fork(authSaga), fork(adminSaga), fork(witnessesSaga), fork(hostsSaga), fork(salonsSaga), fork(guestSaga)]);
}

export const getLanguagesNames = (languagesList: Array<{ id: number; name: string }>, languageId) => {
	if (languageId) {
		const langugeName = languagesList.find(lang => lang.id === languageId).name;
		if (langugeName) return langugeName;
	}
	return '';
};
