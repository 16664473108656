import React, { useState, useEffect } from 'react';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import setErrorString from './setErrorString';
import { useTranslation } from 'react-i18next';
import clearSearch from '../../../assets/icons/clearSearch.svg';
import styled from 'styled-components/macro';
import getDirection from '../../../utils/getLanguageDirection';

interface IErrorHandlerProps {
	errorHandlerKeys: { parameter: string; message: string }[];
}

const ErrorHandler: React.FC<IErrorHandlerProps> = ({ errorHandlerKeys }) => {
	const [isOpen, setIsOpen] = useState(false);
	const { t } = useTranslation();
	const langDirection = getDirection();

	useEffect(() => {
		if (errorHandlerKeys.length) setIsOpen(true);
	}, [errorHandlerKeys]);

	return (
		<Snackbar
			onClose={() => setIsOpen(false)}
			open={isOpen}
			autoHideDuration={5000}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
		>
			<SnackbarContent
				style={{
					backgroundColor: 'red',
					fontSize: '1.6rem'
				}}
				message={
					<SnackbarContainer direction={langDirection}>
						<ExitButton onClick={() => setIsOpen(false)} src={clearSearch} alt="exit" />
						{errorHandlerKeys.length && setErrorString(errorHandlerKeys[0].message, t)}
					</SnackbarContainer>
				}
			/>
		</Snackbar>
	);
};

const ExitButton = styled.img`
	cursor: pointer;
	margin-inline-end: 1rem;
`;

const SnackbarContainer = styled.span<{ direction }>`
	display: flex;
	justify-content: center;
	direction: ${({ direction }) => (direction === 'rtl' ? 'ltr' : 'rtl')};
`;

export default ErrorHandler;
