import { takeLatest, put, call } from 'redux-saga/effects';
import * as actions from '../actions/auth/auth.action';
import { createHostSuccess } from '../actions/hosts/hosts.action';
import { createGuestcall } from '../apiCalls/guests';
import { authActionTypes } from '../actions/auth/auth.types';
import axios from 'axios';
import history from '../../history';
import { API_URL } from '../../utils/constants/api';
import { setAxiosHeader } from '../middleware/auth.middleware';
import { routes, buildPath } from '../../utils/constants/routes';
import { adminUsers } from '../../utils/constants/usersTypes';
import setErrorDataArray from '../../components/common/ErrorHandler/setErrorDataArray';
import { handleErrorFromServer } from '../actions/errors/error.action';
import sagaSetErrorHelperFn from '../../components/common/ErrorHandler/sagaSetErrorHelperFn';
import { convertUserSelected } from '../apiCalls/general';
import i18 from '../../i18n';

const switchableRoles = ['host', 'guest'];

function* Login(action) {
	const { formValues, usingModal, modalCallback } = action.payload;

	try {
		const { data } = yield axios.post(`${API_URL}/api/auth/login/`, formValues);
		if(adminUsers.includes(data.user.role)){
			yield put(actions.loginSuccess(data));
			setAxiosHeader(data.token);
		}

		const {
			user: { role, host, guest, should_ask_to_convert },
		} = data;

		if (usingModal && modalCallback !== null) {
			if (modalCallback && role === 'guest') {
				yield call(modalCallback(guest.registration_status));
			} else {
				yield call(modalCallback);
			}
		} else {
			if (switchableRoles.includes(role)) {
				should_ask_to_convert ? yield put(actions.openChangeRolesModal(true)) : yield navigate(data);
			} else {
				yield navigate(data);
			}
		}

		// save host ID in store
		yield put(createHostSuccess(host?.id ?? null));
	} catch (e) {
		const { data } = e.response;
		yield put(actions.loginFailure(Object.keys(data)));
	}
}

function* navigate(data) {
	const {
		user: { role, confirm_policy },
	} = data;

	if (adminUsers.includes(role)) {
		if (!confirm_policy) {
			yield call(() => history.push('/privacy_policy_heb'));
		}
		// navigate to admin page after success
		if (!!confirm_policy) {
			yield call(() => history.push('/admin'));
		}
	} else {
		yield put(handleErrorFromServer([{message: i18.t("login.userNotAdmin"), parameter: i18.t("login.userNotAdmin")}]));
	}
}

function* forgotPasswordLogin(action) {
	try {
		const { data, onPasswordChange } = action.payload;
		const response = yield axios.post(`${API_URL}/api/auth/login/`, data);
		const { user } = response.data;
		yield put(actions.loginSuccess(response.data));
		setAxiosHeader(response.data.token);
		yield call(() => onPasswordChange(user));
		if (user.host) {
			const hostId = user.host.id;
			yield put(createHostSuccess(hostId));
		}
	} catch (e) {
		console.log(e);
	}
}

function* silentLogin(action) {
	try {
		const { token, role } = action.payload;
		const rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
		if (token && rememberMe) {
			if (adminUsers.includes(role)) {
				yield call(() => history.push('/admin'));
			} else if (role === 'host') {
				// navigato to host
			}
		}
	} catch (e) { }
}

function* registerUser(action) {
	const { formValues, onError, usingModal, modalCallback } = action.payload;
	try {
		const { data } = yield axios.post(`${API_URL}/api/auth/registration/`, formValues);
		yield put(actions.registerUserSuccess(data));
		setAxiosHeader(data.token);
		const {
			user: { role },
		} = data;
		if (role === 'host') {
			// navigate to create salon step one page when registration is succefull
			yield call(() => history.push(routes.register.createSalonVideo));
		} else if (role === 'guest' && !usingModal) {
			yield call(() => history.push(routes.register.registerGuestStepTwo));
		} else if (role === 'guest' && usingModal) {
			if (modalCallback !== null) {
				yield call(modalCallback);
			}
		}
	} catch (e) {
		const { data } = e.response;
		const errorArray = setErrorDataArray(data);
		if (data.email) {
			yield call(() => onError('register.errors.emailExist'));
		}
		yield put(handleErrorFromServer(errorArray));
	}
}

function* registerGuest(action) {
	const { formValues, step, guestId: guest, usingModal, modalCallback } = action.payload;
	try {
		const { data } = yield call(() => createGuestcall(guest.id, { ...formValues, registration_status: step }));
		if (usingModal && modalCallback !== null) {
			yield call(modalCallback);
		} else {
			yield call(() => history.push(routes.register.registerGuestResult, { guest: data.id }));
		}
		yield put(actions.registerGuestSuccess({ guest: data.id, guest_registration_status: data.registration_status }));
	} catch (e) {
		const errorArray = sagaSetErrorHelperFn(e);
		yield put(handleErrorFromServer(errorArray));
	}
}

function* confirmPolicyRequest(action) {
	const { data } = yield call(axios, action.request);
	yield put(actions.confirmPolicySuccess(data));
	yield call(() => history.push('/admin'));
}

function* sendLoginSmsCode(action) {
	try {
		const { phone_number } = action.request.data;
		yield call(axios, action.request);
		yield put(actions.sendLoginSmsCodeSuccess(phone_number));
		yield call(() => history.push(routes.loginWithSms.codeVerification));
	} catch (e) {
	
		
		yield put(actions.sendLoginSmsCodeFailure(Object.keys(e.response.data??{})));
	}
}

function* loginCodeVerification(action) {
	try {
		const { data } = yield call(axios, action.request);
		const { isResetPassword } = action.config;
	
		
		yield put(actions.loginCodeVerificationSuccess(data));
		setAxiosHeader(data.token);
		const {
			user: { host },
		} = data;


		isResetPassword ? history.push(routes.forgotPassword.password) : yield navigate(data);

		// save host ID in store
		yield put(createHostSuccess(host?.id ?? null));
	} catch (e) {
		yield put(actions.loginCodeVerificationFailure(e))
	}
}

function* changeHostToGuest(action) {
	try {
		const { data } = yield call(axios, action.request);
		yield put(actions.changeHostToGuestSuccess(data));
		yield put(actions.closeChangeRolesModal(false));
		yield call(() => history.push(buildPath(routes.guestProfile.profile, { id: data.id })));
		yield call(() => convertUserSelected());
		if ( action.request.callback ) {
			action.request.callback(data)
		}
	} catch (error) {
		yield put(actions.changeHostToGuestFailure(error));
	}
}

function* changeGuestToHost(action) {
	try {
		const { data } = yield call(axios, action.request);
		yield put(actions.changeGuestToHostSuccess(data));
		yield put(actions.closeChangeRolesModal(false));
		yield call(() => convertUserSelected());
		yield call(() => history.push(routes.register.createSalonDetails));
	} catch (error) {
		yield put(actions.changeGuestToHostFailure(error));
	}
}

function* updateUser(action) {
	try {
		const { data } = yield call(axios, action.request);
		yield put(actions.updateUserSuccess(data));
		if (data.role === 'host') {
			yield call(() => history.push(routes.register.createSalonVideo));
		} else if (data.role === 'guest') {
			yield call(() => history.push(routes.register.registerGuestStepTwo));
		}
	} catch (error) {
		console.log(error);
		
	}
	
}

function* authSaga() {
	yield takeLatest(authActionTypes.LOGIN_REQUEST, Login);
	yield takeLatest(authActionTypes.REGISTER_USER, registerUser);
	yield takeLatest(authActionTypes.REGISTER_GUEST, registerGuest);
	yield takeLatest(authActionTypes.SILENT_LOGIN, silentLogin);
	yield takeLatest(authActionTypes.FORGOT_PASSWORD_LOGIN, forgotPasswordLogin);
	yield takeLatest(authActionTypes.CONFIRM_POLICY_REQUEST, confirmPolicyRequest);
	yield takeLatest(authActionTypes.SEND_LOGIN_SMS_CODE_REQUEST, sendLoginSmsCode);
	yield takeLatest(authActionTypes.LOGIN_CODE_VERIFICATION_REQUEST, loginCodeVerification);
	yield takeLatest(authActionTypes.CHANGE_HOST_TO_GUEST_REQUEST, changeHostToGuest);
	yield takeLatest(authActionTypes.CHANGE_GUEST_TO_HOST_REQUEST, changeGuestToHost);
	yield takeLatest(authActionTypes.UPDATE_USER_REQUEST, updateUser);
}

export default authSaga;
