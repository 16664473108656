import { instance as axios } from '../middleware/auth.middleware';
import {
	IAdminSalonsDashboard,
	IAdminHostsDashboard,
	IAdminGuestsDashboard,
	IAdminWitnessesDashboard
} from '../../interfaces/admin';

export const getSalonsDashboard = async (filtersParams): Promise<IAdminSalonsDashboard> => {
	const res = await axios.get(`/api/host_salons_dashboard/`, { params: filtersParams });
	return res.data;
};

export const getHostsDashboard = async (filtersParams): Promise<IAdminHostsDashboard> => {
	const res = await axios.get(`/api/hosts_dashboard/`, { params: filtersParams });
	return res.data;
};

export const getGuestsDashboard = async (filtersParams): Promise<IAdminGuestsDashboard> => {
	const res = await axios.get(`/api/guests_dashboard/`, { params: filtersParams });
	return res.data;
};

export const getWitnessesDashboard = async (filtersParams): Promise<IAdminWitnessesDashboard> => {
	const res = await axios.get(`/api/witnesses_dashboard/`, { params: filtersParams });
	return res.data;
};

export const getCitiesList = () => {
	return axios.get(`/api/cities/`);
};

export const getCommunitiesList = () => {
	return axios.get(`/api/communities/`);
};

export const getLanguagesList = () => {
	return axios.get(`/api/languages/`);
};

export const getAdditionalPrograms = () => {
	return axios.get(`/api/witnesses/take_part_in_additional_programs/`);
};

export const getSalondaysList = () => axios.get(`/api/salondays/`);

export const getVolunteersListByIdCall = (communityId: number) => {
	return axios.get(`/api/communities/${communityId}/volunteers_list/`);
};

export const assignedNewSalon = (hostSalonId: number, witnessSalonId: number) => {
	return axios.post('api/salons/', { host_salon: hostSalonId, witness_salon: witnessSalonId });
};

export const assignedNewSalonNewWitness = (salonId: number, witnessSalonId: number) => {
	return axios.patch(`api/salons/${salonId}/`, { witness_salon: witnessSalonId });
};
