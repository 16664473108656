export enum witnessActionTypes {
	GET_WITNESSES_LIST = 'GET_WITNESSES_LIST',
	GET_WITNESSES_LIST_SUCCESS = 'GET_WITNESSES_LIST_SUCCESS',
	GET_WITNESSES_LIST_FAILURE = 'GET_WITNESSES_LIST_FAILURE',
	GET_WITNESSES_LIST_LOADING = 'GET_WITNESSES_LIST_LOADING',

	GET_WITNESS_PROFILE = 'GET_WITNESS_PROFILE',
	GET_WITNESS_PROFILE_SUCCESS = 'GET_WITNESS_PROFILE_SUCCESS',
	GET_WITNESS_PROFILE_FAILURE = 'GET_WITNESS_PROFILE_FAILURE',

	CLEAR_WITNESS_PROFILE = 'CLEAR_WITNESS_PROFILE',

	UPDATE_WITNESS_PROFILE = 'UPDATE_WITNESS_PROFILE',
	UPDATE_WITNESS_PROFILE_SUCCESS = 'UPDATE_WITNESS_PROFILE_SUCCESS',
	UPDATE_WITNESS_PROFILE_FAILURE = 'UPDATE_WITNESS_PROFILE_FAILURE',

	GET_WITNESS_SALONS = 'GET_WITNESS_SALONS',
	GET_WITNESS_SALONS_SUCCESS = 'GET_WITNESS_SALONS_SUCCESS',

	GET_ASSIGNED_FUTURE_SALONS = 'GET_ASSIGNED_FUTURE_SALONS',
	GET_ASSIGNED_FUTURE_SALONS_SUCCESS = 'GET_ASSIGNED_FUTURE_SALONS_SUCCESS',

	ADD_WITNESS_YEAR_COMMENT = 'ADD_WITNESS_YEAR_COMMENT',
	ADD_WITNESS_YEAR_COMMENT_SUCCESS = 'ADD_WITNESS_YEAR_COMMENT_SUCCESS',
	ADD_WITNESS_YEAR_COMMENT_FAILURE = 'ADD_WITNESS_YEAR_COMMENT_FAILURE',

	GET_WITNESS_YEAR_COMMENT = 'GET_YEAR_COMMENT',
	GET_WITNESS_YEAR_COMMENT_SUCCESS = 'GET_YEAR_COMMENT_SUCCESS',
	GET_WITNESS_YEAR_COMMENT_FAILURE = 'GET_YEAR_COMMENT_SUCCESS_FAILURE',

	UPDATE_WITNESS_YEAR_COMMENT = 'UPDATE_WITNESS_YEAR_COMMENT',
	UPDATE_WITNESS_YEAR_COMMENT_SUCCESS = 'UPDATE_WITNESS_YEAR_COMMENT_SUCCESS',
	UPDATE_WITNESS_YEAR_COMMENT_FAILURE = 'UPDATE_WITNESS_YEAR_COMMENT_FAILURE',

	ADD_WITNESS_COMMENT = 'ADD_WITNESS_COMMENT',
	ADD_WITNESS_COMMENT_SUCCESS = 'ADD_WITNESS_COMMENT_SUCCESS',

	GET_WITNESS_COMMENT = 'GET_WITNESS_COMMENTS',
	GET_WITNESS_COMMENT_SUCCESS = 'GET_WITNESS_COMMENTS_SUCCESS',

	GET_PAST_SALONS = 'GET_PAST_SALONS',
	GET_PAST_SALONS_SUCCESS = 'GET_PAST_SALONS_SUCCESS',
	GET_PAST_SALONS_FAILURE = 'GET_PAST_SALONS_FAILURE',

	GET_WITNESS_FUTURES_SALONS = 'GET_WITNESS_FUTURES_SALONS',
	GET_WITNESS_FUTURES_SALONS_SUCCESS = 'GET_WITNESS_FUTURES_SALONS_SUCCESS',

	GET_WITNESS_AVAILBALITY_SALONS = 'GET_WITNESS_AVAILBALITY_SALONS',
	GET_WITNESS_AVAILBALITY_SALONS_SUCCESS = 'GET_WITNESS_AVAILBALITY_SALONS_SUCCESS',
	GET_WITNESS_AVAILBALITY_SALONS_FAILURE = 'GET_WITNESS_AVAILBALITY_SALONS_FAILURE',
	CLEAR_WITNESS_AVAILBALITY_SALONS = 'CLEAR_WITNESS_AVAILBALITY_SALONS',

	UPDATE_WITNESS_AVAILBALITY_SALONS = 'UPDATE_WITNESS_AVAILBALITY_SALONS',
	UPDATE_WITNESS_AVAILBALITY_SALONS_SUCCESS = 'UPDATE_WITNESS_AVAILBALITY_SALONS_SUCCESS',

	GET_WITNESS_DETAILS_FOR_HOST = 'GET_WITNESS_FOR_HOST_DETAILS',
	GET_WITNESS_DETAILS_FOR_HOST_SUCCESS = 'GET_WITNESS_DETAILS_FOR_HOST_SUCCESS',
	GET_WITNESS_DETAILS_FOR_HOST_FAILURE = 'GET_WITNESS_DETAILS_FOR_HOST_FAILURE',

	DELETE_WITNESS_PROFILE = 'DELETE_WITNESS_PROFILE',
	DELETE_WITNESS_ASSIGEND_SALON = 'DELETE_WITNESS_ASSIGEND_SALON',
	DELETE_WITNESS_TEAM_COMMENT = 'DELETE_WITNESS_TEAM_COMMENT',
	DELETE_WITNESS_TEAM_COMMENT_SUCCESS = 'DELETE_WITNESS_TEAM_COMMENT_SUCCESS',

	GET_ALL_WITNESS_LIST = 'GET_ALL_WITNESS_LIST',
	GET_ALL_WITNESS_LIST_SUCCESS = 'GET_ALL_WITNESS_LIST_SUCCESS'
}
