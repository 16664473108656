import { createGlobalStyle } from 'styled-components/macro';
import { bodyFont } from './utils/css/fonts';
import { colorMain } from './utils/css/colors';
import { headerHeight } from './utils/css/sizes';

export const GlobalStyle = createGlobalStyle`
	*,
	*::after,
	*::before {
			margin: 0;
			padding: 0;
			box-sizing: inherit;
	}

  html {
		font-family: ${bodyFont};
		color: ${colorMain};
		font-weight: normal;
		font-size: 62.5%;
		box-sizing: border-box;
	}

	body {
		font-size: 1.4rem;
		height: calc(100vh - (${headerHeight}px));
		/* height: 100%; */
	}
	#root{
        height: 100%;
	}
`;
