import React from 'react';
import { CheckBoxContainer, CheckBoxLabel, CheckBoxInput, Label } from './checkBoxStyle';
import { ICheckBoxProps } from '../../../../interfaces/inputs';

const Checkbox: React.FC<ICheckBoxProps> = ({ label, isChecked, onChange, checkboxKey, name, error, className = '' }) => {
	return (
		<CheckBoxContainer haveLabel={!!label} className={className}>
			<CheckBoxInput name={name} type="checkbox" id={checkboxKey} checked={isChecked} onChange={onChange} />
			<CheckBoxLabel htmlFor={checkboxKey} error={error}></CheckBoxLabel>
			{label && <Label>{label}</Label>}
		</CheckBoxContainer>
	);
};

export default Checkbox;
