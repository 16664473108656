import React from 'react';
import styled, { css } from 'styled-components';
import {
	colorMain,
	colorWhite,
	buttonShadow,
	colorYellow,
	colorYellowHover,
	colorDarkGrey,
	colorDarkGreyHover,
	colorRed,
	colorRedHover,
	colorTransparentShareSalon,
	colorTransparentShareSalonHover,
	colorTransparentCloseSalon,
	colorTransparentCloseSalonnHover,
} from '../../utils/css/colors';
import { button, buttonOutlined } from '../../utils/css/typography';
import { bodyFont } from '../../utils/css/fonts';
import { mobile } from '../../utils/css/media';

interface IProps {
	colorType?: string;
	isBold?: boolean;
	disabled?: boolean;
	outlined?: boolean;
	applyShadow?: boolean;
	type?: string;
	width?: number | string;
	height?: number | string;
	padding?: string;
	isMargin?: string;
	borderProps?: string;
	colorProps?: string;
	form?: string;
	isFontBold?: boolean;
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
	className?: string;
}

const ButtonSmall: React.FC<IProps> = ({ children, ...props }) => {
	return <S.Button {...props}>{children}</S.Button>;
};

const handleColorType = colorType => {
	switch (colorType) {
		case colorYellow:
			return colorYellowHover;
		case colorDarkGrey:
			return colorDarkGreyHover;
		case colorRed:
			return colorRedHover;
		case colorTransparentShareSalon:
			return colorTransparentShareSalonHover;
		case colorTransparentCloseSalon:
			return colorTransparentCloseSalonnHover;
		default:
			return colorYellow;
	}
};

const S: any = {};
S.Button = styled.button<IProps>`
	background: ${props => props.colorType};
	color: ${({ isBold, outlined }) => (isBold || outlined ? colorMain : colorWhite)};
	min-width: 9rem;
	white-space: nowrap;
	width: ${({ width }) => (width ? width : 'unset')}%;
	height: ${({ height }) => height && height};
	${button};
	${({ outlined }) => outlined && buttonOutlined};
	box-shadow: ${({ applyShadow }) => applyShadow && buttonShadow};
	padding: ${({ padding }) => (padding ? padding : '0.6rem 1.4rem')};
	margin-inline-end: ${props => (props.isMargin ? props.isMargin : '')};
	border: ${props => props.borderProps};
	color: ${props => props.colorProps};
	font-family: ${bodyFont};
	font-weight: ${({ isFontBold }) => (isFontBold ? 'bold' : 'unset')};
	border-color: ${props => (props.colorProps && props.outlined ? props.colorProps : 'unset')};
	transition: all 0.3s ease-out;

	:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
	:focus {
		filter: brightness(85%);
	}
	:hover {
		background: ${({ colorType }) => handleColorType(colorType)};
	}
	${mobile(css`
		font-size: 1.1rem;
		margin: 0.5rem;
		min-width: 2rem;

	`)};
`;

export default ButtonSmall;
