import styled, { css } from 'styled-components/macro';
import {
	colorWhite,
	colorBlack,
	colorDarkGrey,
	colorYellow,
	colorDarkBlue,
	colorYellowHover,
	colorDarkGreyHover,
	colorPink
} from '../../../utils/css/colors';
import { mobile } from '../../../utils/css/media';
import ButtonSmall from '../../common/ButtonSmall';
import { Link } from 'react-router-dom';
import { boldFont, bodyFont } from '../../../utils/css/fonts';
import { body12, body14, body16, logoHeader, h6 } from '../../../utils/css/typography';
import Checkbox from '../../common/Input/CheckBox';
import LoginInput from '../Input';
import PasswordInput from '../Input/PasswordInput';
import { FormContainer as RegisterForm } from '../Register/registerStyle';
import { Dialog } from '@material-ui/core';

// @ts-ignore
export const Form = styled(RegisterForm)`
	margin-top: 5rem;

	> div {
		margin-bottom: 3.5rem;
	}

	> .loginStyle__ButtonContainer-sc-pbyb9r-7 {
		margin: auto 1rem 3rem auto;
	}
`;

export const FormContainer = styled.form<{
	justifyContent?: string;
	minHeight?: string;
	minWidth?: string;
	padding?: string;
	maxWidth?: string;
}>`
	display: flex;
	z-index: 100;
	flex-direction: column;
	align-items: center;
	justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'unset')};
	background: ${colorWhite};
	padding: ${({ padding }) => (padding ? padding : '3rem 6rem 1rem')};
	border-radius: 6px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
	max-width: 45%;
	min-height: ${({ minHeight }) => (minHeight ? minHeight : 'unset')};
	min-width: ${({ minWidth }) => (minWidth ? minWidth : 'unset')};

	${mobile(css`
		max-width: 93%;
		padding: 3.9rem 2.7rem;
	`)};
`;

export const Login = styled(LoginInput)`
	.MuiInputBase-root {
		&::before,
		&::after {
			width: 86%;
			right: 4rem;
		}
	}
`;

export const Password = styled(PasswordInput)`
	.MuiInputBase-root {
		&::before,
		&::after {
			width: 86%;
			right: 4rem;
		}
	}
`;

export const MiddleSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin: 6% 0 9%;
`;

export const ForgotPassword = styled(Link)`
	color: ${colorBlack};
	text-decoration: underline;
`;

export const SeperatorContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-top: 1.5rem;
`;

export const ButtonContainer = styled.div`
	&.buttonContainer {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	> :last-child {
		margin-top: 1.2rem;
	}

	${mobile(css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex-direction: column;
		> :last-child {
			margin-top: 2.2rem;
		}
	`)};
`;

export const LoginButton = styled(ButtonSmall) <{
	padding?: string;
	margin?: string;
	maxWidth?: string;
	isWithSms?: boolean;
}>`
	padding: ${({ padding }) => (padding ? padding : '1.4rem')};
	margin: ${({ margin }) => margin && margin};
	background-color: ${({ isWithSms }) => (isWithSms ? colorBlack : colorYellow)};
	color: ${({ isWithSms }) => (isWithSms ? colorWhite : colorDarkGrey)};
	width: 300px;
	height: 4.8rem;
	${body14};
	font-family: ${({ isWithSms }) => (isWithSms ? bodyFont : boldFont)};
	max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'unset')};

	:hover {
		background: ${({ isWithSms }) => (isWithSms ? colorDarkGreyHover : colorYellowHover)};
	}

	${mobile(css`
		margin: 0;
		width: 100%;
	`)};
`;

export const LoginWithSmsBtn = styled(LoginButton)`
	height: 4.8rem;
`;

export const Seperator = styled.div`
	border: 0.5px solid ${colorBlack};
	margin: 0 5px;
	width: 77%;
	opacity: 0.2;
`;

export const LoginFooter = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
	margin-top: 6%;
	margin-bottom: 5%;

	& > button {
		:not(:last-child) {
			margin-inline-end: 1.6rem;
			${mobile(css`
				margin-inline-end: 1.2rem;
			`)};
		}
		margin-bottom: 1rem;
	}

	${mobile(css`
		flex-wrap: nowrap;
		margin-bottom: 0;
	`)};
`;

const socialMediaButton = css`
	font-size: ${body12};
	padding: 0.5rem 1rem;

	${mobile(css`
		width: 14rem;
		padding: 0.5rem 0.7rem;
		font-size: 1.2rem;
		font-family: ${bodyFont};
	`)};
`;

export const GoogleButton = styled(ButtonSmall)`
	${socialMediaButton};
`;

export const FacebookButton = styled(ButtonSmall)`
	${socialMediaButton};
	background-color: ${colorDarkBlue};
`;

export const CompanyLogo = styled.img`
	margin-inline-end: 4px;

	${mobile(css`
		margin-inline-end: 2px;
	`)};
`;

export const GoToRegisterText = styled.p`
	margin: 1% 0;
	font-size: 1.6rem;

	${mobile(css`
		margin: 4% 0;
		font-size: 1.4rem;
	`)};
`;

export const GoToRegisterLink = styled(Link)`
	color: ${colorBlack};
	text-decoration: underline;
`;

export const StyledCheckBox = styled(Checkbox)`
	margin: 0;
	right: 1rem;
`;

export const Logo = styled.div`
	${logoHeader};
	font-size: 7vh;
	margin: 0.5% 0;

	${mobile(css`
		font-size: 4.5rem;
	`)};
`;

export const MobileContactFooter = styled.div`
	width: 100%;
	height: 34px;
	background-color: #333333;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.25px;
`;

export const MobileContactLink = styled.a<{ direction: string }>`
	color: #ffffff;
	text-decoration: underline;
	margin: ${props => (props.direction === 'rtl' ? '0 5px 0 0 ' : '0 0 0 5px')};
`;

export const Modal = styled(Dialog)`
	&.MuiDialog-root {
		.MuiBackdrop-root {
			background-color: rgba(196, 196, 196, 0.8);
		}
	}
	.MuiPaper-root {
		height: 40.8rem;
		min-width: 62.8rem;
		top: -11rem;
		border-radius: 0.6rem;
		direction: rtl;
		@media (max-width: 768px) {
			min-width: 32.8rem;
		}
	}
`;

export const ModalContent = styled.div`
	height: 40.8rem;
	width: 62.8rem;
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	@media (max-width: 768px) {
		width: 32.8rem;
	}
`;

export const ModalTitle = styled.div`
	${h6};
	font-weight: bold;
	text-align: center;
	margin-top: 4.6rem;
	margin-bottom: 1rem;
`;

export const ModalSubtitle = styled.div`
	${body16};
	line-height: 2.2rem;
	padding: 0rem 8.3rem 11.7rem;
	margin: 1rem 0;
	@media (max-width: 768px) {
		padding: 2rem 4rem 5.1rem;
	}
`;

// @ts-ignore
export const RoleButton = styled.button`
	color: ${colorPink};
	text-decoration: underline;
	border: none;
	background: none;
	cursor: pointer;
`;
