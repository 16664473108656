import { ScrollBarStyles } from './../../utils/css/scrollBar';
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';

import loginBackground from '../../assets/icons/loginBackground.svg';
import loginBackgroundSmall from '../../assets/icons/LoginBackgroundSmall.svg';
import { logoHeader, h4, SuccessTitle } from '../../utils/css/typography';
import { colorBlack, colorWhite } from '../../utils/css/colors';
import { mobile } from '../../utils/css/media';
import { ButtonsContainer } from './Register/RegisterHost/style';
import ButtonSmall from '../common/ButtonSmall';

export const Container = styled.div<{ lng?: string; setJustify?: 'space-between' | 'space-around' | 'center' | '' }>`
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: ${props => (props.setJustify ? props.setJustify : 'unset')};
	align-items: center;
	background-image: url(${loginBackground});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100vh;
	overflow-x: hidden;
	direction: ${({ lng }) => (lng ? (lng === 'en' ? 'ltr' : 'rtl') : 'rtl')};

	${mobile(css`
		background-image: url(${loginBackgroundSmall});
		overflow-y: auto;
		height: unset;
		min-height: 100vh;
	`)}
`;

export const cardStyle = css`
	display: flex;
	flex-direction: column;
	align-items: center;
	min-width: 35vw;
	margin: 2% 0;
	background-color: ${colorWhite};
	border-radius: 6px;
	position: relative;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);

	${mobile(css`
		padding: 7% 8%;
		min-width: 86%;
	`)};
`;

export const Card = styled.div<{ alignItems: string }>`
	${cardStyle};
	align-items: ${({ alignItems }) => alignItems};
	padding: 2% 7.3%;
	max-width: 40vw;
	position: relative;
`;

// @ts-ignore
export const GuestSuccsessTitle = styled(SuccessTitle)`
	${mobile(css`
		margin-bottom: 4.5rem;
	`)}
`;

export const GuestSucssesImg = styled.img`
	width: 85px;
`;

export const GuestButtonContainer = styled(ButtonsContainer)`
	${mobile(css`
		flex-direction: column-reverse;
		width: 100%;
		align-items: center;
	`)}
`;

export const GuestButton = styled(ButtonSmall)`
	margin: 0 5px;
	${mobile(css`
		margin: 1rem 0;
		padding: 14px;
		width: 220px;
	`)}
`;

export const MobilePinkBg = styled.img`
	display: none;

	${mobile(css`
		display: block;
		position: absolute;
		top: -1px;
		right: -5px;
		width: 97px;
	`)}
`;

export const BlueSofa = styled.img`
	position: absolute;
	width: 30%;
	height: 50%;
	left: 3%;
	bottom: 7%;

	${mobile(css`
		display: none;
	`)};
`;

export const PinkSofa = styled.img`
	position: absolute;
	right: 2%;
	bottom: 12%;
	width: 30%;
	height: 40%;

	${mobile(css`
		display: none;
	`)};
`;

export const FlexRow = styled.div<{ showHomePageSection?: boolean }>`
	display: flex;
	justify-content: ${({ showHomePageSection }) => (showHomePageSection ? 'space-between' : 'flex-end')};
	width: 95%;
	padding-top: 3rem;
`;

export const Logo = styled.div`
	${logoHeader};
	cursor: pointer;
`;

export const TextLink = styled(Link)<{ underlined?: boolean }>`
	text-decoration: ${({ underlined }) => (underlined ? 'underline' : 'none')};
	color: ${colorBlack};
`;

export const Row = styled.div<{ lang?: string }>`
	display: flex;
	align-items: center;
	& > span {
		margin-inline-start: 5px;
	}

	& > img {
		transform: ${({ lang }) => (lang === 'en' ? css`rotate(60deg)` : null)};
	}
`;

export const Title = styled.div<{ fontWeight?: string; color?: string }>`
	${h4};
	font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 'normal')};
	color: ${({ color }) => (color ? color : 'rgba(0,0,0,0.87)')};
	${mobile(css`
		font-size: 1.8rem;
		text-align: center;
	`)};
`;

export const TermsContainer = styled.div<{ lngDirection?: string }>`
	padding: 2rem;
	direction: ${({ lngDirection }) => (lngDirection === 'heb' ? 'rtl' : 'ltr')};
	overflow: auto;
	height: 750px;
	max-width: 940px;
	margin: 0 auto;
	${ScrollBarStyles};
`;
