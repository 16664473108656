import styled from 'styled-components/macro';
import { headerHeight, cornerHeight } from '../../../utils/css/sizes';
import { colorYellow, colorDarkGrey, colorMain } from '../../../utils/css/colors';
import { body16, logoHeader } from '../../../utils/css/typography';
import { boldFont } from '../../../utils/css/fonts';

export const Container = styled.header`
	background: ${colorYellow};
	height: calc(${headerHeight}px - ${cornerHeight}px);
	position: sticky;
	top: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	z-index: 100;
	margin-bottom: 20px;
	user-select: none;
`;

export const Actions = styled.div`
	display: flex;
	padding: 2.7rem 1.7rem 0 1.7rem;
	align-items: center;
	color: ${colorDarkGrey};
	${body16};
`;

export const ActionItem = styled.div<{ clickable?: boolean; marginLeft?: number }>`
	text-decoration-line: ${({ clickable }) => clickable && 'underline'};
	cursor: pointer;
	margin-inline-start: 4rem;
	margin-left: ${({ marginLeft }) => marginLeft}rem;
	font-size: 1.6rem;
`;

export const LogoContainer = styled.a`
	margin: 0 1rem 11px 3.8rem;
	${logoHeader};
	color: ${colorMain};
	-webkit-text-stroke: 0.4px black;
`;

export const TextItem = styled.span<{ isSelected: boolean }>`
	margin: 0 8px;
	font-family: ${({ isSelected }) => isSelected && boldFont};
`;

export const StyledAnchor = styled.a`
	font-size: 16px;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.87);
	text-decoration: none;
	margin: 0 0.5rem;
`;
