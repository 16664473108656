import { IHost } from './hosts';
import { IGuest } from './guests';

export interface LoginFormValues {
	email: string;
	password: string;
}

export interface IAuthState {
	token: string | null;
	id: string;
	email: string;
	first_name: string;
	last_name: string;
	role: string;
	host: IHost;
	guest: IGuest;
	guest_registration_status: unknown;
	errorKeys: string[];
	confirm_policy: boolean;
	phoneNumber: string;
	isShowModal: boolean;
	isChangeRoleThisYear: boolean;
}
export interface IUserUpdate {
	first_name: string;
	last_name: string;
	email: string;
}

export enum UserTypes {
	SUPER_ADMIN = 'super admin',
	GENERAL_ADMIN = 'general admin',
	GLOBAL_ADMIN = 'global admin',
	VOLUNTEER_ADMIN = 'volunteer admin',
	HOST_ADMIN = 'host admin',
	TESTIMONIAL_ADMIN = 'testimonial admin',
	HOST = 'host',
	GUEST = 'guest',
	WITNESS = 'witness',
}
