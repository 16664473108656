import { adminActionTypes } from './admin.types';
import { City, Community, Language } from '../../../interfaces/admin';

//Get Cities

export const getCities = () => ({
	type: adminActionTypes.GET_CITIES,
	request: {
		url: '/api/cities/',
		method: 'GET'
	}
});

export const getCitiesSuccess = (cities: City[]) => ({
	type: adminActionTypes.GET_CITIES_SUCCESS,
	payload: cities
});

export const getCitiesFailure = data => ({
	type: adminActionTypes.GET_CITIES_FAILURE,
	payload: data
});

//Get Communities

export const getCommunities = () => ({
	type: adminActionTypes.GET_COMMUNITIES,
	request: {
		url: `/api/communities/`,
		method: 'GET'
	}
});

export const getCommunitiesSuccess = (communities: Community[]) => ({
	type: adminActionTypes.GET_COMMUNITIES_SUCCESS,
	payload: communities
});

export const getCommunitiesFailure = (error: string) => ({
	type: adminActionTypes.GET_COMMUNITIES_FAILURE,
	payload: error
});

//Get Languages

export const getLanguages = () => ({
	type: adminActionTypes.GET_LANGUAGES,
	request: {
		url: `/api/languages/`,
		method: 'GET'
	}
});

export const getLanguagesSuccess = (languages: Language) => ({
	type: adminActionTypes.GET_LANGUAGES_SUCCESS,
	payload: languages
});

export const getLanguagesFailure = (error: string) => ({
	type: adminActionTypes.GET_LANGUAGES_FAILURE,
	payload: error
});

export const setLoading = isLoading => ({
	type: adminActionTypes.SET_IS_LOADING,
	payload: isLoading
});

export const getInitData = () => ({
	type: adminActionTypes.GET_INIT_DATA
});

export const getAppData = () => ({
	type: adminActionTypes.GET_APP_DATA
});

export const setNumOfActiveFilters = (num: number) => ({
	type: adminActionTypes.SET_NUMBER_OF_ACTIVE_FILTERS,
	payload: num
});

export const createWitness = (data, callback) => ({
	type: adminActionTypes.CREATE_WITNESS,
	payload: callback,
	request: {
		url: `/api/witnesses/`,
		method: 'POST',
		data
	}
});

export const createWitnessSuccess = () => ({
	type: adminActionTypes.CREATE_WITNESS_SUCCESS
});

export const createWitnessFailure = (error: string) => ({
	type: adminActionTypes.CREATE_WITNESS_FAILURE,
	payload: error
});

export const createWitnessDuplicateValidation = (data, callback: Function) => ({
	type: adminActionTypes.CREATE_WITNESS_DUPLICATE_VALIDATION,
	payload: callback,
	request: {
		url: `/api/witnesses_duplicate_validation/`,
		method: 'POST',
		data
	}
});

export const createWitnessDuplicateValidationFailure = (names: string[]) => ({
	type: adminActionTypes.CREATE_WITNESS_DUPLICATE_VALIDATION_FAILURE,
	payload: names
});

export const createWitnessDuplicateValidationSuccess = () => ({
	type: adminActionTypes.CREATE_WITNESS_DUPLICATE_VALIDATION_SUCCESS
});

export const setNewWitnessId = (id: number) => ({
	type: adminActionTypes.SET_NEW_WITNESS_ID,
	payload: id
});

//Get voluntress List by id

export const getVolunteersListById = (volunteerId: number) => {
	return {
		type: adminActionTypes.GET_VOLUNTEERS_LIST_BY_ID,
		payload: volunteerId
	};
};

export const getVolunteersListByIdSuccsses = data => ({
	type: adminActionTypes.GET_VOLUNTEERS_LIST_BY_ID_SUCCESS,
	payload: data
});

export const getUserCity = (value: string) => ({
	type: adminActionTypes.GET_USER_CITY_ADDRESS,
	payload: value
});

export const clearUserCity = () => ({
	type: adminActionTypes.CLEAR_USER_CITY_ADDRESS
});
