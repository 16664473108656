import { witnessActionTypes } from './witnesses.types';

// Get Witness
export const getWitnessesList = params => {
	return {
		type: witnessActionTypes.GET_WITNESSES_LIST,
		payload: params
	};
};
export const getWitnessesListSuccess = data => {
	return {
		type: witnessActionTypes.GET_WITNESSES_LIST_SUCCESS,
		payload: data
	};
};

export const getAllWitnessList = params => {
	return {
		type: witnessActionTypes.GET_ALL_WITNESS_LIST,
		payload: params
	};
};

export const getAllWitnessListSuccess = data => {
	return {
		type: witnessActionTypes.GET_ALL_WITNESS_LIST_SUCCESS,
		payload: data
	};
};

export const getWitnessesListFailure = data => {
	return {
		type: witnessActionTypes.GET_WITNESSES_LIST_FAILURE,
		payload: data
	};
};

//Get Witness Profile
export const getWitnessProfile = (witnessId: string) => ({
	type: witnessActionTypes.GET_WITNESS_PROFILE,
	payload: witnessId
});

export const getWitnessProfileSuccess = data => ({
	type: witnessActionTypes.GET_WITNESS_PROFILE_SUCCESS,
	payload: data
});

export const getWitnessProfileFailure = data => ({
	type: witnessActionTypes.GET_WITNESS_PROFILE_FAILURE,
	payload: data
});

export const clearWitnessProfile = () => ({
	type: witnessActionTypes.CLEAR_WITNESS_PROFILE
});

//Update Witness Profile
export const updateWitnessProfile = (witnessId: number, data: any) => ({
	type: witnessActionTypes.UPDATE_WITNESS_PROFILE,
	payload: { witnessId, witnessUpdateData: data }
});

export const updateWitnessProfileSuccess = data => ({
	type: witnessActionTypes.UPDATE_WITNESS_PROFILE_SUCCESS,
	payload: data
});

export const updateWitnessProfileFailure = data => ({
	type: witnessActionTypes.UPDATE_WITNESS_PROFILE_FAILURE,
	payload: data
});

//Get Witness Salons
export const getWitnessSalons = (witnessId: string) => ({
	type: witnessActionTypes.GET_WITNESS_SALONS,
	payload: witnessId
});

//Get witness assigned salons
export const getAssignedFutureSalons = (witnessId: string) => ({
	type: witnessActionTypes.GET_ASSIGNED_FUTURE_SALONS,
	request: {
		url: `/api/salons/witness_future_salons/?witness=${witnessId}`,
		method: 'GET'
	}
});

//witness year comment
export const getWitnessYearComment = (witnessId: string) => ({
	type: witnessActionTypes.GET_WITNESS_YEAR_COMMENT,
	request: {
		url: `/api/witnesses/${witnessId}/witness_year_comments/`,
		method: 'GET'
	}
});

export const getWitnessYearCommentSuccess = data => ({
	type: witnessActionTypes.GET_WITNESS_YEAR_COMMENT_SUCCESS,
	payload: data
});

export const getWitnessYearCommentFailure = data => ({
	type: witnessActionTypes.GET_WITNESS_YEAR_COMMENT_FAILURE,
	payload: data
});

export const addWitnessYearComment = (witnessId: string, data: { key: string; value: string }) => ({
	type: witnessActionTypes.ADD_WITNESS_YEAR_COMMENT,
	request: {
		url: `/api/witnesses/${witnessId}/witness_year_comments/`,
		method: 'POST',
		data: data
	}
});

export const updateWitnessYearComment = (witnessId: string, commentId: number, data: { key: string; value: string }) => ({
	type: witnessActionTypes.UPDATE_WITNESS_YEAR_COMMENT,
	request: {
		url: `/api/witnesses/${witnessId}/witness_year_comments/${commentId}/`,
		method: 'PATCH',
		data: data
	}
});

export const updateWitnessYearCommentSuccess = data => ({
	type: witnessActionTypes.UPDATE_WITNESS_YEAR_COMMENT_SUCCESS,
	payload: data
});

export const updateWitnessYearCommentFailure = data => ({
	type: witnessActionTypes.UPDATE_WITNESS_YEAR_COMMENT_FAILURE,
	payload: data
});

//Add + Get witness comment
export const addWitnessComment = (witnessId: string, data: { key: string; value: string }) => {
	return {
		type: witnessActionTypes.ADD_WITNESS_COMMENT,
		payload: {
			witnessId,
			commentData: data
		}
	};
};

export const addWitnessCommentSuccess = data => {
	return {
		type: witnessActionTypes.ADD_WITNESS_COMMENT_SUCCESS,
		payload: data
	};
};

export const getWitnessComment = (id: string) => ({
	type: witnessActionTypes.GET_WITNESS_COMMENT,
	request: {
		url: `/api/witnesses/${id}/witness_comments/`,
		method: 'GET'
	}
});

export const getWitnessCommentSuccess = data => ({
	type: witnessActionTypes.GET_WITNESS_COMMENT_SUCCESS,
	payload: data
});

export const getPastSalons = (id: string) => ({
	type: witnessActionTypes.GET_PAST_SALONS,
	request: {
		url: `/api/salons/witness_past_salons/?witness=${id}`,
		method: 'GET'
	}
});

export const getPastSalonsSuccess = data => {
	return {
		type: witnessActionTypes.GET_PAST_SALONS_SUCCESS,
		payload: data
	};
};

export const getPastSalonsFailure = (error: string) => ({
	type: witnessActionTypes.GET_PAST_SALONS_FAILURE,
	payload: error
});

export const getFutureSalons = (witnessId: string) => ({
	type: witnessActionTypes.GET_WITNESS_FUTURES_SALONS,
	payload: witnessId
});

//Get + Update witness availablity salons
export const getWitnessAvailbalitySalons = (witnessId: string) => {
	return {
		type: witnessActionTypes.GET_WITNESS_AVAILBALITY_SALONS,
		payload: {
			witnessId
		}
	};
};

export const getWitnessAvailbalitySalonsSuccess = data => ({
	type: witnessActionTypes.GET_WITNESS_AVAILBALITY_SALONS_SUCCESS,
	payload: data
});

export const updateWitnessAvailabilitySalons = (witnessId: string, data: any) => {
	return {
		type: witnessActionTypes.UPDATE_WITNESS_AVAILBALITY_SALONS,
		payload: {
			witnessId,
			data
		}
	};
};

export const updateWitnessAvailabilitySalonsSuccess = data => ({
	type: witnessActionTypes.UPDATE_WITNESS_AVAILBALITY_SALONS_SUCCESS,
	payload: data
});

// Get witness details for host
export const getWitnessDetailsForHost = witnessId => {
	return {
		type: witnessActionTypes.GET_WITNESS_DETAILS_FOR_HOST,
		payload: witnessId
	};
};

export const getWitnessDetailsForHostSuccess = data => ({
	type: witnessActionTypes.GET_WITNESS_DETAILS_FOR_HOST_SUCCESS,
	payload: data
});

export const getWitnessDetailsForHostFailure = error => ({
	type: witnessActionTypes.GET_WITNESS_DETAILS_FOR_HOST_FAILURE,
	payload: error
});

//Delete witness Profile
export const deleteWitnessProfile = wintnessId => ({
	type: witnessActionTypes.DELETE_WITNESS_PROFILE,
	payload: wintnessId
});

//Clear witness Avalibale salons
export const clearWitnessAvailableSalons = () => ({
	type: witnessActionTypes.CLEAR_WITNESS_AVAILBALITY_SALONS
});

//Delete witness assgiend salon
export const deleteWitnessAssigendSalon = (salonId: number) => ({
	type: witnessActionTypes.DELETE_WITNESS_ASSIGEND_SALON,
	payload: salonId
});

//Delete witness team comment
export const deleteWitnessTeamComment = (witnessId: string | number, commentId: number) => ({
	type: witnessActionTypes.DELETE_WITNESS_TEAM_COMMENT,
	payload: { witnessId, commentId }
});

export const deleteWitnessTeamCommentSuccess = commentId => ({
	type: witnessActionTypes.DELETE_WITNESS_TEAM_COMMENT_SUCCESS,
	payload: commentId
});
