import { takeLatest, put, call } from 'redux-saga/effects';
import moment from 'moment';
import * as actions from '../actions/hosts/hosts.action';
import { hostsActionTypes } from '../actions/hosts/hosts.types';
import {
	getHostProfileCall,
	getHostSalonsSummaryCall,
	updateHostProfileCall,
	createHostCall,
	addnNewHostSalonCall,
	updateHostSalonCall,
	getHostSalonByHostIdCall,
	deleteHostSalonByIdCall,
	deleteWitnessTeamCommentCall,
	getHostsListCall,
	getAllHostsListCall,
	deleteHostProfileCall
} from '../apiCalls/hosts';
import history from '../../history';
import { routes } from '../../utils/constants/routes';
import { handleErrorFromServer } from '../actions/errors/error.action';
import sagaSetErrorHelperFn from '../../components/common/ErrorHandler/sagaSetErrorHelperFn';
import { getFiltersStrings } from '../../utils/createTableFiltersRequest';

function* getHostProfile(action) {
	try {
		const { data } = yield call(() => getHostProfileCall(action.payload));
		yield put({ type: hostsActionTypes.GET_HOST_PROFILE_SUCCESS, payload: data });
	} catch (e) {
		const errorArray = sagaSetErrorHelperFn(e);

		yield put(handleErrorFromServer(errorArray));
	}
}

function* updateHostProfile(action) {
	try {
		const payload = action.payload;
		const { data } = yield call(() => updateHostProfileCall(payload.hostId, payload.data));
		yield put({ type: hostsActionTypes.UPDATE_HOST_PROFILE_SUCCESS, payload: data });
	} catch (e) {
		const errorArray = sagaSetErrorHelperFn(e);

		yield put(handleErrorFromServer(errorArray));
	}
}

function* getHostSalonsList(action) {
	try {
		const { data } = yield call(() => getHostSalonsSummaryCall(action.payload));
		const salonsByYear = {};
		data.forEach(salon => {
			const salonYear = moment(salon.event_time).format('YYYY');
			if (salonsByYear[salonYear]) {
				salonsByYear[salonYear].push(salon);
			} else {
				salonsByYear[salonYear] = [salon];
			}
		});
		yield put({ type: hostsActionTypes.GET_HOST_SALONS_LIST_SUCCESS, payload: salonsByYear });
	} catch (e) {
		const errorArray = sagaSetErrorHelperFn(e);

		yield put(handleErrorFromServer(errorArray));
	}
}

function* createHost(action) {
	const { hostData, isDigital } = action.payload;
	try {
		// create host
		const {
			data: { id }
		} = yield call(() => createHostCall(hostData));

		// save host ID in store
		yield put(actions.createHostSuccess(id));
		// navigate to step two
		yield call(() =>
			history.push({
				pathname: routes.register.createSalonDetails,
				state: { isDigital }
			})
		);
	} catch (e) {
		const errorArray = sagaSetErrorHelperFn(e);

		yield put(handleErrorFromServer(errorArray));
	}
}

function* createHostSalonForExistingUser(action) {
	const { data, id } = action.payload;
	const { hostSalonsData, hostData } = data;
	try {
		const { data } = yield call(() => addnNewHostSalonCall({ ...hostSalonsData, ...hostData, host: id }));
		yield put(actions.getHostSalonsSuccess([data]));

		// navigate to step two
		yield call(() => history.push(routes.register.createSalonResult));
	} catch (e) {
		const errorArray = sagaSetErrorHelperFn(e);
		yield put(handleErrorFromServer(errorArray));
	}
}

function* updateHostSalon(action) {
	const {
		data: { hostData, hostSalonsData },
		hostSalonsId,
		step
	} = action.payload;
	try {
		const {
			data: { host }
		} = yield call(() => updateHostSalonCall(hostSalonsId, hostSalonsData));
		//  update host registration status
		yield call(() => updateHostProfileCall(host, { ...hostData, registration_status: step }));
		// navigate to route according to step
		const route = step === 2 ? routes.register.createSalonDetails : routes.register.createSalonResult;
		yield call(() => history.push(route));
	} catch (e) {
		const errorArray = sagaSetErrorHelperFn(e);
		yield put(handleErrorFromServer(errorArray));
	}
}

function* getHostSalons(action) {
	const hostId = action.payload;
	try {
		const { data } = yield call(getHostSalonByHostIdCall, hostId);
		yield put(actions.getHostSalonsSuccess(data));
	} catch (e) {
		const errorArray = sagaSetErrorHelperFn(e);
		yield put(handleErrorFromServer(errorArray));
	}
}

function* deleteHostSalonById(action) {
	const { hostSalonId } = action.payload;
	try {
		yield call(() => deleteHostSalonByIdCall(hostSalonId));
	} catch (e) {
		const errorArray = sagaSetErrorHelperFn(e);
		yield put(handleErrorFromServer(errorArray));
	}
}

function* deleteHostSalonComment(action) {
	const { hostSalonId, commentId } = action.payload;
	try {
		yield call(() => deleteWitnessTeamCommentCall(hostSalonId, commentId));
	} catch (e) {
		const errorArray = sagaSetErrorHelperFn(e);
		yield put(handleErrorFromServer(errorArray));
	}
}

function* getAllHosts(action) {
	try {
		yield put({ type: hostsActionTypes.GET_HOST_LIST_LOADING });
		const { payload } = action;
		const { data } = yield call(() => getHostsListCall(payload));
		const hostsList = data.results,
			hostsCountAllPages = data.count,
			totalHostsAmountNoFilters = data.total_results;

		yield put(
			actions.getHostsListSucsses({
				hostsList,
				hostsCountAllPages,
				totalHostsAmountNoFilters,
				...getFiltersStrings(data.next, data.previous)
			})
		);
		// yield put({ type: 'REQUEST_SUCCESS' });
	} catch (e) {
		const errorArray = sagaSetErrorHelperFn(e);

		yield put(handleErrorFromServer(errorArray));
	}
}

function* getAllHostsList(action) {
	const { payload } = action;
	try {
		const params = { ...payload, pagination: false };
		const { data } = yield call(() => getAllHostsListCall(params));
		yield put(actions.getAllHostsListSuccess(data));
	} catch (e) {
		action.payload.callback(false);
	}
}
function* deleteHostProfile(action) {
	try {
		yield call(() => deleteHostProfileCall(action.payload));
		history.push(routes.hosts.list);
	} catch (e) {
		const errorArray = sagaSetErrorHelperFn(e);
		yield put(handleErrorFromServer(errorArray));
	}
}
function* hostsSaga() {
	yield takeLatest(hostsActionTypes.GET_HOST_PROFILE, getHostProfile);
	yield takeLatest(hostsActionTypes.GET_HOST_SALONS_LIST, getHostSalonsList);
	yield takeLatest(hostsActionTypes.UPDATE_HOST_PROFILE, updateHostProfile);
	yield takeLatest(hostsActionTypes.CREATE_HOST, createHost);
	yield takeLatest(hostsActionTypes.UPDATE_HOST_SALON, updateHostSalon);
	yield takeLatest(hostsActionTypes.CREATE_HOST_SALON_FOR_EXISTING_USER, createHostSalonForExistingUser);
	yield takeLatest(hostsActionTypes.GET_HOST_SALONS, getHostSalons);
	yield takeLatest(hostsActionTypes.DELETE_HOST_SALON_BY_ID, deleteHostSalonById);
	yield takeLatest(hostsActionTypes.DELETE_HOST_SALON_COMMENT, deleteHostSalonComment);
	yield takeLatest(hostsActionTypes.GET_ALL_HOSTS, getAllHosts);
	yield takeLatest(hostsActionTypes.GET_ALL_HOSTS_EXPORT, getAllHostsList);
	yield takeLatest(hostsActionTypes.DELETE_HOST_PROFILE, deleteHostProfile);
}

export default hostsSaga;
