import styled, { css } from 'styled-components/macro';
import { colorBlack } from '../../../utils/css/colors';
import ButtonSmall from '../../common/ButtonSmall';
import { Link } from 'react-router-dom';
import { mobile } from '../../../utils/css/media';
import { cardStyle } from '../CommonStyles';

export const Card = styled.div<{ marginTop?: string }>`
	${cardStyle};
	padding: 6rem 11rem;
	margin-top: ${({ marginTop }) => (marginTop ? marginTop : '5%')};
	z-index: 100;
	min-width: 23vw;

	${mobile(css`
		min-width: 93%;
		padding: 7.5rem 5rem;
	`)};
`;

export const ButtonsContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const RegisterButton = styled(ButtonSmall)`
	padding: 1rem 6rem;
	margin: 1rem 0;
`;

export const GoToLoginText = styled.p`
	margin-top: 6%;
	font-size: 16px;

	${mobile(css`
		margin-top: 25%;
		font-size: 1.4rem;
	`)};
`;

export const GoToLoginLink = styled(Link)`
	color: ${colorBlack};
	text-decoration: underline;
`;

export const FormContainer = styled.form.attrs(() => ({ noValidate: true, autoComplete: 'off' }))<{
	width?: string;
	height?: string;
	margin?: string;
}>`
	width: ${({ width }) => (width ? width : '100%')};
	height: ${({ height }) => height && height};
	position: relative;
	margin: ${({ margin }) => margin && margin};
`;
