import React from 'react';
import styled, { css } from 'styled-components/macro';

import { colorBlack } from '../../utils/css/colors';

const Row = styled.div`
	width: 18px;
	height: 2px;
	background: ${colorBlack};
	margin: 3px 0;
	opacity: 0.54;
	transition: 0.4s;
`;

const Container = styled.span<{ clicked: boolean }>`
	${({ clicked }) =>
		clicked &&
		css`
			${Row}:nth-child(1) {
				transform: rotate(-45deg) translate(0px, 7px);
			}
			${Row}:nth-child(2) {
				opacity: 0;
			}
			${Row}:nth-child(3) {
				transform: rotate(45deg) translate(0px, -7px);
			}
		`};
`;

const Hamburger = ({ isHamburgerClicked = false, toggleHamburgerClicked = () => {} } = {}) => (
	<Container onClick={toggleHamburgerClicked} clicked={isHamburgerClicked}>
		<Row />
		<Row />
		<Row />
	</Container>
);
export default Hamburger;
