import { css } from 'styled-components/macro';
import { inputBorderRadius } from './sizes';
import { bodyFont } from './fonts';
import { body14 } from './typography';

export const input = css`
	width: 100%;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: ${inputBorderRadius}px;
	padding: 9px;
	outline: none;
	resize: none;
	font-family: ${bodyFont};
	${body14};
`;
