import { takeLatest, put, call, select } from 'redux-saga/effects';
import * as actions from '../actions/admin/admin.action';
import { adminActionTypes } from '../actions/admin/admin.types';
import axios from 'axios';
import {
  getCitiesList as getCitiesListCall,
  getCommunitiesList as getCommunitiesListCall,
  getLanguagesList as getLanguagesListCall,
  getAdditionalPrograms as getAdditionalProgramsCall,
  getVolunteersListByIdCall,
  getSalondaysList as getSalondaysListCall,
} from '../apiCalls/admin';
import setErrorDataArray from '../../components/common/ErrorHandler/setErrorDataArray';
import { handleErrorFromServer } from '../actions/errors/error.action';

const selectLanguages = (store) => store.adminReducer.languages;

function* getInitData() {
  // Get languages from state
  let languages = yield select(selectLanguages);

  try {
    yield put({
      type: adminActionTypes.GET_INIT_DATA_START,
    });

    // If cities exist in local storage use them else use getCitiesListCall
    let cities = [];
    if (localStorage.getItem('cities')) {
      cities = JSON.parse(localStorage.getItem('cities'));
    } else {
      const { data: citiesData } = yield call(getCitiesListCall);
      cities = citiesData;
      localStorage.setItem('cities', JSON.stringify(cities));
    }
    const { data: communities } = yield call(getCommunitiesListCall);
    // Get language if array is empty
    if (languages.length === 0) {
      const { data } = yield call(getLanguagesListCall);
      languages = data;
    }
    yield put({
      type: adminActionTypes.GET_INIT_DATA_SUCCESS,
      payload: { cities, communities, languages },
    });
  } catch (e) {
    // error related to 401 status handled in axios instance interceptor
  }
}

function* getAppData() {
  try {
    const { data: salondays } = yield call(getSalondaysListCall);
    yield put({
      type: adminActionTypes.GET_APP_DATA_SUCCESS,
      payload: { salondays },
    });
  } catch (e) {}
}

function* getCities(action) {
  try {
    const { data } = yield call(axios, action.request);
    yield put(actions.getCitiesSuccess(data));
  } catch (e) {
    yield put(actions.getCitiesFailure(e));
  }
}

function* getCommunities(action) {
  try {
    const { data } = yield call(axios, action.request);
    yield put(actions.getCommunitiesSuccess(data));
  } catch (e) {
    const { data } = e.response;
    yield put(actions.getCommunitiesFailure(data.detail));
  }
}

function* getLanguages(action) {
  // Get languages from state
  let languages = yield select(selectLanguages);

  try {
    // Get language if array is empty
    if (languages.length === 0) {
      const { data } = yield call(axios, action.request);
      languages = data;
    }

    yield put(actions.getLanguagesSuccess(languages));
  } catch (e) {
    const { data } = e.response;
    yield put(actions.getLanguagesFailure(data.detail));
  }
}

function* createWitness(action) {
  const callback = action.payload;
  try {
    const { data } = yield call(axios, action.request);
    yield put(actions.setNewWitnessId(data.id));
    // show modal when request is succeful
    yield call(callback);
  } catch (e) {
    yield put(actions.createWitnessFailure(e));
  }
}

function* createWitnessDuplicateValidation(action) {
  const callback = action.payload;
  try {
    const { request } = yield call(axios, action.request);
    if (request.status === 200) {
      yield put(actions.createWitnessDuplicateValidationSuccess());
    }
  } catch (e) {
    const { data } = e.response;
    const { names } = data;

    if (names) {
      yield put(actions.createWitnessDuplicateValidationFailure(names));
      yield call(() => callback());
    } else {
      const errorArray = setErrorDataArray(data);
      yield put(handleErrorFromServer(errorArray));
    }
  }
}

function* getVolunteersListById(action) {
  try {
    const { data } = yield call(() => getVolunteersListByIdCall(action.payload));
    yield put(actions.getVolunteersListByIdSuccsses(data));
  } catch (e) {
    const { data } = e.response;

    const errorArray = setErrorDataArray(data);
    yield put(handleErrorFromServer(errorArray));
  }
}

function* adminSaga() {
  yield takeLatest(adminActionTypes.GET_INIT_DATA, getInitData);
  yield takeLatest(adminActionTypes.GET_APP_DATA, getAppData);
  yield takeLatest(adminActionTypes.GET_CITIES, getCities);
  yield takeLatest(adminActionTypes.GET_COMMUNITIES, getCommunities);
  yield takeLatest(adminActionTypes.GET_LANGUAGES, getLanguages);
  yield takeLatest(adminActionTypes.CREATE_WITNESS, createWitness);
  yield takeLatest(adminActionTypes.CREATE_WITNESS_DUPLICATE_VALIDATION, createWitnessDuplicateValidation);
  yield takeLatest(adminActionTypes.GET_VOLUNTEERS_LIST_BY_ID, getVolunteersListById);
}

export default adminSaga;
