import styled, { css } from 'styled-components/macro';
import { InputAdornment } from '@material-ui/core';
import { bodyFont } from '../../../utils/css/fonts';
import TextField from '@material-ui/core/TextField';
import { input } from '../../../utils/css/input';
import { MenuItem } from '@material-ui/core';

export const InputContainer = styled.div<{ display?: string }>`
	position: relative;
	justify-content: center;
	margin-bottom: 0.5rem;
	width: 100%;
	display: ${({ display }) => display};
`;

const inputStyle = css`
	.MuiInputBase-input {
		line-height: 1;
	}
	.MuiInputLabel-formControl {
		font-size: 1.4rem !important;
		font-family: ${bodyFont} !important;
		font-weight: 700;
	}
	.MuiFormHelperText-root {
		font-family: ${bodyFont} !important;
	}
	.MuiFormHelperText-root {
		font-size: 1.4rem;
		max-width: 228px;
	}
	.MuiInput-input {
		font-size: 1.4rem !important;
		font-family: ${bodyFont} !important;
	}
`;

export const StyledInput = styled(TextField)`
	${inputStyle};
	/* remove input type=number arrows */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	/* Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
		text-align: left;
		&::placeholder {
			text-align: right !important;
		}
	}
	input[type='time'] {
		text-align: center;
	}
	/* removes input blue background color for chrome autocomplete */
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 30px white inset !important;
		box-shadow: 0 0 0 30px white inset;
	}
`;

export const InputIcon = styled.img`
	height: 20px;
	width: 20px;
`;

export const ShowPasswordIcon = styled.img`
	cursor: pointer;
`;
export const StyledInputAdornment = styled(InputAdornment)`
	margin-inline-end: 17px;
`;

export const Textarea = styled.textarea.attrs(() => ({ tabIndex: 1 }))<{ minHeight?: string; widthOfInput?: string }>`
	height: ${props => (props.minHeight ? props.minHeight : '9rem')};
	vertical-align: top;
	${input};

	width: ${({ widthOfInput }) => (widthOfInput ? widthOfInput : '')};

	::placeholder {
		font-weight: normal;
		color: rgba(0, 0, 0, 0.87);
		opacity: 0.54;
		font-family: ${bodyFont};
		line-height: 20px;
		letter-spacing: 0.25px;
	}
`;

export const MenuOption = styled(MenuItem)`
	> div {
		font-size: 1.4rem;
	}
`;
