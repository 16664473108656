import React from 'react';
import { redirectToErrorPage } from '../../../store/actions/errors/error.action';

class ErrorBoundary extends React.Component {
	state = { hasError: false };

	componentDidCatch(error, info) {
		this.setState({ hasError: true });
	}

	render() {
		if (this.state.hasError) {
			redirectToErrorPage('errorBoundary');
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
