import React from 'react';
import { useSelector } from 'react-redux';
import { adminUsers } from './constants/usersTypes';

const useIsUserAdmin = () => {
	// @ts-ignore
	const role = useSelector(({ authReducer: { role } }) => role);
	const isUserAdmin = React.useMemo(() => adminUsers.findIndex(admin => role === admin) > -1, [role]);
	return isUserAdmin;
};

export default useIsUserAdmin;
